import React from 'react'
import { Link } from 'react-router-dom';

import mySustainability from '../../assets/images/mySustainability.jpeg';
import playAndLearn from '../../assets/images/play_and_learn.svg';
import personalizedExperience from '../../assets/images/personalized_experience.svg';
import verifyYourActions from '../../assets/images/verify_your_actions.svg';
import earth from '../../assets/images/earth.svg';
import stayEngaged from '../../assets/images/environmental_impact.svg';
import earnRewards from '../../assets/images/earn_rewards.svg';
import joinCommunity from '../../assets/images/join_community.svg';
import tryItOut from '../../assets/images/tryItOut.jpeg';
import integration from '../../assets/images/integration.svg';
import promotion from '../../assets/images/promotion.svg';
import verificationReporting from '../../assets/images/verification_reporting.svg';
import communityImpact from '../../assets/images/community_impact.svg';
import joinUs from '../../assets/images/joinUs.jpeg';

import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';
import { LearnMoreButton } from '../../assets/components/js/navigation/LearnMoreButton';

export const MySustainabilityApp = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col item-center pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-8 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-6'>
          <div className='relative w-full h-90 overflow-hidden rounded-[30px]'>
            <p className='absolute grid place-items-center w-full top-[166px] h-12 xl:h-12 text-white font-semibold text-2xl xl:text-3.5xl z-10'>
              My Sustainability App
            </p>
            <img
              className='absolute top-0 xl:top-[-480px] w-full h-full xl:h-[1120px] object-cover xl:object-fill'
              src={mySustainability}
              alt='sustainable water bottle'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-30 rounded-[30px]'></div>
          </div>
          <p className='max-w-[924px] mx-auto text-base lg:text-xl xl:text-2xl'>
            The My Sustainability app is your gateway to making a real impact on the environment while having fun and earning rewards.
          </p>
        </div>

        <div className='w-full grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-2 xl:gap-y-[27px]'>

          <p className='col-span-1 lg:col-span-3 font-bold text-center text-2xl xl:text-3.5xl'>
            How it works:
          </p>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={playAndLearn}
                  alt='person playing games'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl lg:text-[22px] xl:text-2xl'>Play and Learn</p>
                <ul className='w-full xl:w-88 text-base list-disc pl-6' >
                  <li>Answer trivia questions about sustainability and earn points.</li>
                  <li>Take eco-friendly actions and get rewarded.</li>
                </ul>
              </div>
            </div>
            {/* <LearnMoreButton link='/energy-assessments' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={personalizedExperience}
                  alt='mobile notifications'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl lg:text-[22px] xl:text-2xl'>Personalized Experience</p>
                <p className='w-full xl:w-88 text-base'>
                  Our smart algorithms tailor the game to your interests and habits, keeping it fun and relevant.
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/incentives' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={verifyYourActions}
                  alt='person carrying painting'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl lg:text-[22px] xl:text-2xl'>Verify Your Actions</p>
                <p className='w-full xl:w-88 text-base'>
                  Use your phone's camera to verify your sustainable actions and earn points instantly.
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/project-management' text='Learn More' /> */}
          </div>

        </div>

        <div className='flex flex-col gap-y-2 xl:gap-y-8'>

          <p className='w-full font-bold text-2xl xl:text-3.5xl text-left xl:text-center'>
            Why you'll love it:
          </p>

          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-8 text-left'>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={earth} alt='earth logo' />
              <p className='w-full xl:w-136 h-6 font-semibold text-lg'>Make a Difference</p>
              <p className='w-full xl:w-136 text-base'>Access to a wide range of incentives can significantly reduce the cost of energy efficiency upgrades.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={stayEngaged} alt='seedling logo' />
              <p className='w-full xl:w-136 h-6 font-semibold text-lg'>Stay Engaged</p>
              <p className='w-full xl:w-136 text-base'>Keep playing and learning to maintain your green habits over time.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={earnRewards} alt='coin stack logo' />
              <p className='w-full xl:w-136 h-6 font-semibold text-lg'>Earn Rewards</p>
              <p className='w-full xl:w-136 text-base'>Your efforts can earn you real rewards, making your eco-friendly journey even more exciting.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={joinCommunity} alt='community logo' />
              <p className='w-full xl:w-136 h-6 font-semibold text-lg'>Join a Community</p>
              <p className='w-full xl:w-136 text-base'>Connect with others who are passionate about sustainability and share your progress.</p>
            </div>
          </div>

        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-4'>
          <img
            className='w-full xl:w-136 h-90 object-cover object-center rounded-[30px]'
            src={tryItOut}
            alt='mobile'
            loading='lazy'
          />
          <div className='w-full xl:w-[543px] flex flex-col gap-y-2 text-left'>
            <div className='w-full flex flex-col items-start gap-y-6'>
              <div className='flex flex-col gap-y-2'>
                <p className='font-semibold text-2xl xl:text-3.5xl'>Try it out</p>
                <p className='text-base'>
                  The My Sustainability app is more than just a game - it's a way to contribute to a greener future. Download the app, start playing, and make a difference one question at a time.
                  <br /><br />Download now and join the movement for a sustainable future!
                </p>
              </div>
              <LearnMoreButton link={'/property-management'} text={'Download'} />
            </div>
          </div>
        </div>

        <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 text-left'>
          <p className='md:col-span-2 font-semibold text-3.5xl'>
            Incentive Providers: Partner with Us
          </p>
          <div className='flex flex-col gap-y-[22px]'>
            <p className='max-w-[543px] font-semibold text-2xl'>Targeted Engagement</p>
            <ul className='max-w-[543px] list-disc pl-6 text-base' >
              <li>Reach a dedicated audience of eco-conscious users who are actively seeking to reduce their environmental impact.</li>
              <li>Our machine learning algorithms ensure that your incentives are promoted to users most likely to benefit from them.</li>
            </ul>
          </div>
          <div className='flex flex-col gap-y-[22px]'>
            <p className='max-w-[543px] font-semibold text-2xl'>Data-Driven Insights</p>
            <ul className='max-w-[543px] list-disc pl-6 text-base' >
              <li>Gain access to detailed analytics on user behaviors and preferences.</li>
              <li>Understand how your incentives are being utilized and measure their effectiveness in real-time.</li>
            </ul>
          </div>
          <div className='flex flex-col gap-y-[22px]'>
            <p className='max-w-[543px] font-semibold text-2xl'>Enhanced Visibility</p>
            <ul className='max-w-[543px] list-disc pl-6 text-base' >
              <li>Feature your incentives prominently within the app, increasing awareness and participation.</li>
              <li>Highlight your commitment to sustainability and community support.</li>
            </ul>
          </div>
          <div className='flex flex-col gap-y-[22px]'>
            <p className='max-w-[543px] font-semibold text-2xl'>Streamlined Deployment</p>
            <ul className='max-w-[543px] list-disc pl-6 text-base' >
              <li>Easily manage and deploy your incentives through our user-friendly platform.</li>
              <li>Our automated systems ensure quick and accurate verification of user actions, simplifying the reward process.</li>
            </ul>
          </div>
        </div>

        <div className='flex flex-col gap-y-2 xl:gap-y-8'>
          <p className='w-full font-semibold text-2xl xl:text-3.5xl text-left xl:text-center'>
            How it works:
          </p>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-8 text-left'>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={integration} alt='gear logo' />
              <p className='max-w-136 h-6 font-semibold text-lg'>Integration</p>
              <p className='max-w-136 text-base'>We seamlessly integrate your incentives into the app, aligning them with relevant trivia questions and sustainable actions.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={promotion} alt='loudspeaker logo' />
              <p className='max-w-136 h-6 font-semibold text-lg'>Promotion</p>
              <p className='max-w-136 text-base'>Promote your incentives to users based on their engagement and actions, ensuring high visibility and uptake.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={verificationReporting} alt='graph logo' />
              <p className='max-w-136 h-6 font-semibold text-lg'>Verification and Reporting</p>
              <ul className='max-w-136 list-disc pl-6 text-base' >
                <li>Utilize our computer vision algorithms to autonomously verify user actions.</li>
                <li>Receive comprehensive reports on the impact of your incentives, helping you refine and improve your offerings.</li>
              </ul>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={communityImpact} alt='helping hand' />
              <p className='max-w-136 h-6 font-semibold text-lg'>Community Impact</p>
              <p className='max-w-136 text-base'>By sponsoring incentives, you support a broader movement towards sustainability, enhancing your reputation and fostering community goodwill.</p>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-4'>
          <img
            className='w-full xl:w-136 h-90 object-cover object-center rounded-[30px]'
            src={joinUs}
            alt='two person talking'
            loading='lazy'
          />
          <div className='w-full xl:w-[543px] flex flex-col gap-y-2 text-left'>
            <div className='w-full flex flex-col items-start gap-y-6'>
              <div className='flex flex-col gap-y-2'>
                <p className='font-semibold text-2xl xl:text-3.5xl'>Join Us</p>
                <p className='text-base'>
                  Become a sponsored incentive provider on the My Sustainability app and take part in driving meaningful change. Together, we can empower individuals to make sustainable choices and amplify the impact of your incentives.
                </p>
              </div>
              <Link to='/careers'>
                <button
                  className='inline-block flex items-center h-10 rounded-[30px] px-14 py-2 text-white font-semibold text-lg bg-[#0070E0]'
                >Get started
                </button>
              </Link>
            </div>
          </div>
        </div>

      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};