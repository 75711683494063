import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const PreRegister = () => {
    const navigate = useNavigate();

  useEffect(() => {
    // Redirect to google.com when this component mounts
    window.location.replace('https://forms.gle/y3h9X2jX2bqxnUa98');
  }, []);

  return null; // No rendering
};

export default PreRegister;
