import React from 'react';
import { Link } from 'react-router-dom';
import arrowBackIcon from '../../images/arrowBack.png'; 

const ArrowBack = ({ to }) => {
    return (
        <Link to={to}>
            <img src={arrowBackIcon} alt="Back" />
        </Link>
    );
};

export default ArrowBack;
