



export const CalenderDay = (props) => {


    if (!props.dayObject) {
        return <div></div>
    }

    if (!props.dayObject.isAvailable) {
        return <button disabled className="text-gray-300 w-[100%] flex items-center justify-center w-[30px] h-[30px] pb-[4px] select-none">
            {props.dayObject.dateNum}
        </button>
    }

    return <button className={ "num" + props.dayObject.dateNum + " w-[100%] calender-date-num flex items-center justify-center w-[30px] h-[30px] pb-[4px] select-none"} onClick={() => {
        props.changeDay(props.dayObject)

        if (props.selectedDay.current) {
            props.selectedDay.current.classList.remove("selected-date-num")
        }
        props.selectedDay.current =  document.querySelector(".num"  + props.dayObject.dateNum)
        document.querySelector(".num" + props.dayObject.dateNum).classList.add("selected-date-num")
    }}>
        {props.dayObject.dateNum}


    </button>
}
