import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.png';
import styles from './AccountVerification.module.css';
import arrowImage from '../../assets/images/arrowBack.png'
import { useNavigate } from 'react-router-dom';

export const AccountVerification = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(30);

    const navigateToSignup = () => {
        navigate('/signup-profileselection');
    };

    useEffect(() => {
        const timer = countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
        if (countdown === 0) {
            setError(''); // Reset error message when countdown resets
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const handleCodeChange = (e) => {
        const input = e.target.value;
        if (input.length <= 6) {
            setCode(input);
            if (input.length === 6) {
                setError('');
            }
        }
    };

    const handleSubmit = () => {
        if (code.length === 6) {
            // Handle code verification here
            console.log('Code submitted:', code);
            navigate("/signin")
        } else {
            setError('Enter a valid code.');
        }
    };

    const resendCode = () => {
        if (countdown === 0) {
            console.log('Resending code...');
            setCountdown(30);
        }
    };

    return (
        <div className={styles.container}>
            <img src={logo} alt="Logo" className={styles.logo}/>
            <h1 className={styles.header}>Let’s confirm it’s you</h1>
            <p className={styles.subtitle}>
                Enter the secure code we sent to your email. Please check spam if it’s not in your inbox.
            </p>

            <img
                src={arrowImage}
                alt="Go to signup"
                className={styles.arrow}
                onClick={navigateToSignup}
            />

            <input
                type="text"
                value={code}
                onChange={handleCodeChange}
                placeholder="Enter 6-digit code"
                maxLength="6"
                className={styles.inputCode}
            />
            {error && <p style={{color: 'red'}}>{error}</p>}

            <button
                onClick={handleSubmit}
                disabled={code.length !== 6}
                className={`${styles.verifyButton} ${code.length !== 6 ? styles.disabledButton : ''}`}
            >
                Verify
            </button>

            <p onClick={() => resendCode()} className={styles.resendText}>
                {countdown === 0 ? "Send New Code" : `Resend code in ${countdown}s`}
            </p>
        </div>
    );
};
