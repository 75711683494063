import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import iconCottage from "../../../assets/images/homeCottage.png";
import "../ProjectCategory/projectcategory.css";
import SignUpLayout from "../../../assets/components/js/SignUpLayout";

export const ProjectCategory = () => {
    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const options = [
        {
            icon: iconCottage,
            label: "Residential Homeowner",
            value: "Residential Homeowner",
            path: "residentialhomeowner"
        },
        {
            icon: iconCottage,
            label: "Landlord or Commercial Property Owner",
            value: "Landlord or Commercial Property Owner",
            path: "landlord"
        },
        {
            icon: iconCottage,
            label: "Renter",
            value: "Renter",
            path: "renter"
        },
    ]

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleClick = (index) => {
        setSelectedIndex(index);
    };

    const handleBack = () => {
        navigate('/signin');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Perform  API call here
            alert(`Navigate to ${options[selectedIndex].label} page. Backend API call needs to be added here!`);
            navigate('/'+options[selectedIndex].path);
        } catch (error) {
            console.error("Error occurred during form submission");
        }
    }

    return (
        <>
            <SignUpLayout>
                {options.map((options, index) => (
                    <div
                        key={index}
                        className={`row p-3 my-4 option-btn alignment ${hoveredIndex === index ? "option-active" : "option-inactive"
                            } ${selectedIndex === index
                                ? "option-active"
                                : "option-inactive"
                            }`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(index)}
                    >
                        <div className="col-md-4">
                            <img
                                className="option-icon"
                                src={options.icon}
                                alt={options.label}
                            />
                        </div>
                        <div className="col-md-8 px-2">{options.label}</div>
                    </div>
                ))}
                <div className="row">
                    <div>
                        <button
                            className={`mt-4 w-full text-white py-3 rounded-2xl transition duration-100 ${selectedIndex === null ? 'disabled bg-[#7b7b7b]' : 'bg-[#43CCC4] hover:bg-green-400 pointer'}`}
                            onClick={handleSubmit}
                            disabled={selectedIndex === null}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </SignUpLayout>


        </>
    );
};
