import React from 'react';
import { AllRoutes } from "./routes/AllRoutes";
import './App.css';


function App() {
  return (

    <div className="App">
      <AllRoutes />
    </div>

  );
}

export default App;
