import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import DreamLineLogo from "../../images/dreamline_logo.png";

const MainHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const getLinkClass = (path) => {
        return location.pathname === path ? 'font-bold' : '';
    };

    return (
        <div className="flex flex-col lg:flex-row justify-between items-center lg:px-24 py-4 bg-white">
            <div className="flex flex-row justify-between items-center w-full lg:w-auto mb-2 lg:mb-0">
                <Link to="/" className="flex flex-row items-center">
                    <img src={DreamLineLogo} alt="dream line logo image" className="min-h-16 min-w-fit h-16" />
                </Link>
                <button onClick={toggleMenu} className="lg:hidden">
                    <span className="text-2xl">&#9776;</span>
                </button>
            </div>
            <div className={`flex-col lg:flex-row w-full lg:w-auto items-start lg:items-center mb-2 lg:mb-0 ${isMenuOpen ? 'flex' : 'hidden'} lg:flex`}>
                <ul className="flex flex-col lg:flex-row items-start lg:items-center user-select-none justify-between w-full lg:w-auto">
                    <li className="nav-bar-dropdown user-select-none relative text-center text-xl mb-2 lg:mb-0 group">
                        <Link to="/propertyowners" className={`flex px-2 ${getLinkClass('/propertyowners')}`}> Property Owners <p className="side-arrow-nav">^</p></Link>
                        <ul className="absolute hidden bg-gray-500 z-10 w-48">
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/energy-assessments"> Energy Assessments </Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/incentives"> Incentives </Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/project-management"> Project Management</Link></li>
                        </ul>
                    </li>
                    <li className="nav-bar-dropdown user-select-none relative text-center text-xl mb-2 lg:mb-0 group">
                        <Link to="/incentiveproviders" className={`flex px-2 ${getLinkClass('/incentiveproviders')}`}> Incentive Providers <p className="side-arrow-nav">^</p></Link>
                        <ul className="absolute hidden bg-gray-500 z-10 w-48">
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/incentive-deployment"> Incentive Deployment </Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/"> Project Management</Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/"> Impact Reporting </Link></li>
                        </ul>
                    </li>
                    <li className="nav-bar-dropdown user-select-none relative text-center text-xl mb-2 lg:mb-0 group">
                        <Link to="/about" className={`flex px-2 ${getLinkClass('/about')}`}> About <p className="side-arrow-nav">^</p></Link>
                        <ul className="absolute hidden bg-gray-500 z-10 w-48">
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/our-story"> Our Story </Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/solutions"> Solutions </Link></li>
                            <li className="text-base lg:text-lg hover:font-bold px-4 py-2"> <Link to="/careers"> Careers</Link></li>
                        </ul>
                    </li>
                </ul>
                <div className="flex flex-col lg:hidden items-start">
                    <button className="login-button-nav mb-2 text-lg border-2 border-blue-500 text-black rounded-full px-6 py-3 hover:bg-blue-500 hover:text-white" onClick={() => navigate("/signin")}>Login</button>
                    <button className="signup-button-nav text-lg bg-blue-500 text-white rounded-full px-6 py-3 hover:bg-blue-600" onClick={() => navigate("/signup")}>Sign up</button>
                </div>
            </div>
            <div className="hidden lg:flex items-center space-x-4">
                <button className="login-button-nav text-lg lg:text-xl border-2 border-blue-500 text-black rounded-full px-6 py-3 hover:bg-blue-500 hover:text-white" onClick={() => navigate("/signin")}>Login</button>
                <button className="signup-button-nav text-lg lg:text-xl bg-blue-500 text-white rounded-full px-6 py-3 hover:bg-blue-600" onClick={() => navigate("/signup")}>Sign up</button>
            </div>
        </div>
    );
}

export default MainHeader;
