import React from 'react'
import { Link } from 'react-router-dom';

import smartSolutions from '../../assets/images/smartSolutions.jpeg';
import boostIncentive from '../../assets/images/boost_incentive.svg';
import realTimeInsights from '../../assets/images/real_time_insights.svg';
import freeImpactAnalysis from '../../assets/images/free_impact_analysis.svg';
import rapidIncentive from '../../assets/images/rapidIncentive.jpeg';
import dataDrivenInsights from '../../assets/images/dataDrivenInsights.jpeg';
import impactAnalysisReporting from '../../assets/images/impactAnalysisReporting.jpeg';

import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';

export const IncentiveProviders = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col item-center pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-8 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-6'>
          <div className='relative w-full h-90 overflow-hidden rounded-[30px]'>
            <p className='absolute w-full top-[200px] h-18 lg:top-[180px] lg:h-12 text-[#F5F5F5] font-semibold text-2xl xl:text-3.5xl z-10'>
              Smart Solutions for Incentive Deployment
            </p>
            <img
              className='absolute top-0 lg:top-[-240px] w-full h-full lg:h-[746px] object-cover lg:object-fill'
              src={smartSolutions}
              alt='smart solutions'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-30 rounded-[30px]'></div>
          </div>
          <p className='max-w-[924px] mx-auto text-base lg:text-xl xl:text-2xl'>
            Dreamline is your comprehensive platform for efficiently deploying incentives, analyzing impact, and leveraging data-driven insights to maximize results.
          </p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-4'>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={boostIncentive}
                  alt='Sweet Home'
                  loading='lazy'
                />
              </div>
              <div className='w-full flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Boost your Incentives</p>
                <p className='w-full xl:w-88 text-base'>
                  Rapid deployment and streamlined management of new & current incentives
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/energy-assessments' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={realTimeInsights}
                  alt='bitcoin'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Real-time Insights</p>
                <p className='w-full xl:w-88 text-base'>
                  Get access to real-time feedback from the community for maximum efficiency and impact reporting.
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/incentives' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={freeImpactAnalysis}
                  alt='bitcoin'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Free Impact Analysis</p>
                <p className='w-full xl:w-88 text-base'>
                  Receive a free comprehensive energy analysis evaluating historical data and current conditions creating custom recommendations using predictive analytics
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/project-management' text='Learn More' /> */}
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120'
            src={rapidIncentive}
            alt='train'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Rapid Incentive Deployment</p>
            <p className='text-base'>
              Dreamline excels in efficiently deploying incentives to communities, maximizing effectiveness through smart analytics, a dedicated dream team of experts, and advanced software. Our streamlined process swiftly identifies suitable candidates, ensuring fast, reliable, and impactful outcomes. With Dreamline managing the logistics, you can focus on making a meaningful difference, knowing that your efforts are reaching more individuals and communities effectively.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120 lg:order-last'
            src={dataDrivenInsights}
            alt='data analysis'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Data-Driven Insights</p>
            <p className='text-base'>
              Unlock the power of data-driven insights with Dreamline, guiding smarter decisions for future incentive deployment. Our platform offers valuable insights on impact and incentives, enabling fast access to actionable data at your fingertips. Reimagine tomorrow with Dreamline, where every decision is informed by data, ensuring lasting impact on the communities you serve.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120'
            src={impactAnalysisReporting}
            alt='meeting'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Impact Analysis and Reporting</p>
            <p className='text-base'>
              Dreamline uses smart software and advanced analytics to handle task complexities, freeing you to focus on helping others. We provide valuable insights and analytics about the communities you serve, empowering you to maximize impact effortlessly. Let us handle the intricacies while you make a difference.
            </p>
          </div>
        </div>

        <div className='w-full flex flex-col items-center justify-center gap-y-6 xl:gap-y-0'>
          <p className='max-w-[1112px] xl:h-[58px] font-semibold text-2xl'>
            Embrace the Future of Incentive Deployment with Dreamline.
          </p>
          <div className='flex flex-col gap-y-6 xl:gap-y-[29px]'>
            <p className='max-w-[1117px] text-lg'>
              Streamlined management and analytics at your fingertips. Let us handle the logistics while you focus on impacting communities.
            </p>
            <Link to='/pre-register'>
              <button
                className='inline-block h-10 rounded-[30px] px-14 py-2 text-white font-semibold text-lg bg-[#0070E0]'
              >Get started
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};