import React from "react";
import { useNavigate } from "react-router-dom";
import pageImage from "../../images/group-pic.png";
import dreamlineLogo from "../../images/logo.png";
import iconArrowLeft from "../../images/left-arrow.png";
import signUpStyle from "../css/SignUpLayout.module.css";
const SignUpLayout = ({ children }) => {
  // Pages that use SignupLayout need 'options', see UserProfileReason.js for an example and a 'next button'
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/signin");
  };

  return (
    <div className={`${signUpStyle.main_container} row`}>
      <div className=" col-sm-12 col-md-8">
        <div>
          <img
            src={pageImage}
            className={`${signUpStyle.main_img}`}
            alt="Description of the image"
          />
        </div>
      </div>
      <div className={`col-sm-12 col-md-4 ${signUpStyle.right_section_container}`}>
        <div className="d-flex px-5 py-3">
          <div className={`row mx-5 ${signUpStyle.main_img_div}`}>
            <img src={dreamlineLogo} alt="Description of the image" />
          </div>

          <div className={`row my-4 ${signUpStyle.description_heading}`}>
            <div className="col-md-2" onClick={handleBack}>
              <img
                className={`${signUpStyle.pointer}`}
                src={iconArrowLeft}
                alt="go back"
              />
            </div>
            <div className="col-md-10 px-5 mx-5">
              What describes you the best?
            </div>
          </div>
          <>{children}</>
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
};
export default SignUpLayout;
