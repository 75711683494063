// Import necessary dependencies and assets
import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import styles from './signin.module.css';
import { Link } from 'react-router-dom';
import visible from '../../assets/images/visible.png';
import invisible from '../../assets/images/invisible.png';
import { useNavigate } from 'react-router-dom';

export const SignIn = () => {
    // State variables for the sign-in form
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const navigateToHomePage = () => {
        navigate('/energy-assessment-home');
    };

    // Validation function for the sign-in form
    const validateForm = (event) => {
        if (event) {
            event.preventDefault();
        }
        let isValid = true;

        // Add validation logic similar to the sign-up page if needed
        if (password.length < 8) {
            // Set an error message state for password error
            setPasswordError('Use 8 characters or more for your password.');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    // Function to handle input changes and validate the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }

        // Check if the form is valid
        setIsFormValid(email.length >= 1 && password.length >= 1);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const serverURL = process.env.REACT_APP_SERVER_URL;
        const fetchURL = `${serverURL}/auth/login`;

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch(fetchURL , {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            
            localStorage.setItem('token', data.token );
            localStorage.setItem('firstName', data.user.firstName );
            

            navigateToHomePage();
        } catch (error) {
            console.error('Login failed:', error.message);
            setPasswordError('Invalid email or password');
        }
    };

    // JSX for the sign-in page
    return (
        <div className={styles.container}>
            <img src={logo} alt="Logo" onClick={navigateToHomePage} className={styles.logo}/>
            <h1 className={styles.header}>Welcome back</h1>
            <div className={styles.accountPrompt}>
                <span className={styles.accountPromptText}>Don’t have an account?</span>
                {' '}
                <Link to="/signup" className={styles.accountPromptLink}>Create Now!</Link>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <div style={{
                        position: 'fixed', // Apply fixed positioning
                        width: '322px',
                        height: '62px',
                        top: '426px',
                        left: '912px',
                        borderRadius: '10px',
                    }}>
                        <input
                            style={{borderColor: emailError ? 'red' : '#43CCC4'}}
                            className="px-4 w-full border py-4 rounded-md text-sm outline-none mb-4"
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleInputChange}
                        />
                        <p className='error-info'>{emailError}</p>
                    </div>
                    <div style={{
                        position: 'fixed', // Apply fixed positioning
                        width: '322px',
                        height: '62px',
                        top: '526px',
                        left: '912px',
                        borderRadius: '10px',
                    }}>
                        <input
                            style={{borderColor: passwordError ? 'red' : '#43CCC4'}}
                            className="px-4 w-full border py-4 rounded-md text-sm outline-none mb-4"
                            type={isPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={handleInputChange}
                        />

                        <button
                            type="button"
                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                            className="absolute absolute top-[8px] right-0 pr-4 flex items-center text-sm leading-5"
                        >
                            <img
                                src={isPasswordVisible ? visible : invisible}
                                alt={isPasswordVisible ? 'Hide password' : 'Show password'}
                            />
                        </button>
                        <div style={{
                            position: 'fixed', // Apply fixed positioning
                            width: '322px',
                            height: '37px',
                            top: '586px',
                            left: '1012px',
                            borderRadius: '10px',
                        }}>
                            {/* TODO: Assume the link to forget password is this */}
                            <Link to="/forgot-password" className="text-sm text-grey-600 hover:underline">
                                Forgot password?
                            </Link>
                        </div>
                        <div style={{
                            position: 'fixed', // Apply fixed positioning
                            width: '322px',
                            top: '620px',
                            left: '912px',
                            borderRadius: '10px',
                        }}>
                            <p className='error-info'>{passwordError}</p>
                        </div>
                    </div>
                </div>
                {/* Additional elements or links can be added here */}
                <div style={{
                    position: 'fixed', // Apply fixed positioning
                    width: '322px',
                    height: '59px',
                    top: '629px',
                    left: '912px',
                }}>
                    <button
                        className={`mt-4 w-full text-white py-3 rounded-2xl transition duration-100 ${
                            isFormValid ? "bg-[#43CCC4] hover:bg-green-400" : "bg-gray-400 cursor-not-allowed"
                        }`}
                        type="submit"
                        disabled={!isFormValid}>
                        Login
                    </button>

                </div>
            </form>
        </div>
    );
};
