import logoImage from "../../images/flower-logo.png";
import cottageBlueIcon from '../../images/cottage.png';
import cottageIcon from "../../images/cottageNonBlue.png";
import currencyExchangeIcon from '../../images/currency_exchange.png'
import peopleIcon from '../../images/two-people.png'
import settingsIcon from '../../images/settings.png'
import moreVertIcon from '../../images/more_vert.png'
import settingsBlue from '../../images/settingsBlue.png'
import currencyExchangeBlue from "../../images/currency_exchange_blue.png"
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react"
import '../css/AsessmentTab.css';


export const AssessmentTab = () => {

    const navigation = useNavigate()
    const [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {

        let directory = window.location.pathname
        directory = directory.substring(1)
        directory = directory.split("-")[0]
        
        try {
            let directoryElement = document.querySelector(".icon-" + directory + "-nav");
            directoryElement.querySelector("div").classList.remove("hidden")
            directoryElement.querySelector("p").classList.add("text-[#43CCC4]")
            setCurrentPage(directory)
        }
        catch(e) {
            alert("LEFT NAVIGATION TAB ERROR: Page pathname must be \"energy-assessment-home\", \"my-incentives\", \"invite\", \"settings\"")
        }
        
        
    }, [])


    const goToPage = (page) => {

        navigation(page)
    }


    return <div className="assessment-tab flex flex-col w-[15vw] h-[90vh]  bg-white-500 rounded-br-[50px] shadow-lg justify-[center] items-center absolute z-10 opacity-100 bg-opacity-100" style={
                {boxShadow: '0px 15px 15px rgb(164, 164, 164)',
                backgroundColor: 'white',
            }
            }>
        <img src={logoImage} alt="Dreamline AI flower logo" className="mt-[50px]"/>
        <button className="icon-energy-nav w-[100%] min-h-[50px] mt-[80px] flex flex-row items-center nav-bar-tabs" onClick={() => {goToPage("/energy-assessment-home")}}>
            <div className="w-[10px] bg-blue-500 h-[50px] rounded-br-[20px] rounded-tr-[20px] absolute hidden"></div>
            <img src={ currentPage === "energy" ? cottageBlueIcon : cottageIcon } className="ml-[30px]" alt="cottage icon"/>
            <p className="ml-[15px] mt-[5px]"> Home </p>
        </button>
        <button className="icon-my-nav w-[100%] min-h-[50px] mt-[25px] flex flex-row items-center nav-bar-tabs" onClick={() => {goToPage("/userincentive")}}>
            <div className="w-[10px] bg-blue-500 h-[50px] rounded-br-[20px] rounded-tr-[20px] absolute hidden"></div>
            <img src={currentPage === "my" ? currencyExchangeBlue : currencyExchangeIcon} className="ml-[30px] mt-[10px]" alt="currency icon"/>
            <p className="ml-[15px] mt-[5px]"> Incentives </p>
        </button>
        <button className="icon-invite-nav w-[100%] min-h-[50px] mt-auto flex flex-row items-center nav-bar-tabs invite-tab" onClick={() => {goToPage("/invite")}} disabled>
            <div className="w-[10px] bg-blue-500 h-[50px] rounded-br-[20px] rounded-tr-[20px] absolute hidden"></div>
            <img src={peopleIcon} className="ml-[30px] mt-[10px]" alt="people icon"/>
            <p className="ml-[15px] mt-[5px]"> Invite </p>
            <img src={moreVertIcon } alt="More settings for invite" className="ml-[50px] mt-[10px]"/>
        </button>
        <button className="icon-settings-nav w-[100%] min-h-[50px] mt-[25px] flex flex-row items-center mb-[50px] nav-bar-tabs" onClick={() => {goToPage("/settings")}} disabled>
            <div className="w-[10px] bg-blue-500 h-[50px] rounded-br-[20px] rounded-tr-[20px] absolute hidden"></div>
            <img src={currentPage === "settings" ? settingsBlue : settingsIcon} className="ml-[30px] mt-[10px]" alt="settings icon"/>
            <p className=" ml-[15px] mt-[5px]"> Settings </p>
        </button>

    </div>
}
