import logoImage from "../../images/flower-logo.png"
import logotext from "../../images/logo-text.png"
import faceBookIcon from "../../images/facebook_icon.png"
import linkedinIcon from "../../images/linkedin_icon.png"
import instagramIcon from "../../images/instagram_icon.png"
import xIcon from "../../images/x_icon.png"



export const Footer = () => {

    //#CEFCFA



    return <div className=""><div className="flex w-[100vw] h-[550px] overflow-hidden" style={{
        backgroundColor: 'white',
        boxShadow: '0px 15px 15px gray'
    }}>
        <div className="relative flex-1 hidden md:block">
            <div className="absolute w-[30vw] h-[30vw] max-w-[550px] max-h-[550px] rounded-[50%] bg-blue-500 translate-x-[-100px] mt-[50px]" style={{
                background: "linear-gradient(to bottom, #b1fcf8, white)"
            }}></div>
            <div className="relative flex items-center justify-center mt-[120px] ml-[50px]">
                <img src={logoImage} alt="Dreamline AI logo" className="h-[6vw] max-h-[110px] "/>
                <img src={logotext} alt="Dreamline AI text Logo" className="z-11 h-[3vw] max-h-[80px]"/>
            </div>
        </div>
        <div className="flex flex-col justify-center items-baseline pb-[100px] flex-1 mb-[50px]">
            <p className="font-bold text-[50px]"> Follow Us</p>
            <div className="flex flex-row flex-wrap items-center gap-[15%] w-[100%]">
                <a href="https://facebook.com" target="_blank">
                    <div className="w-[80px] h-[80px] rounded-[50%] bg-[#CEFCFA] flex items-center justify-center">
                        <img src={faceBookIcon} alt="Link to facebook Account" className="h-[50px]"/>
                    </div>
                </a>
                <a href="https://www.instagram.com/cdreamsorg/" target="_blank">
                    <div className="w-[80px] h-[80px] rounded-[50%] bg-[#CEFCFA] flex items-center justify-center">
                        <img src={instagramIcon} alt="Link to Instagram Account" className="h-[50px]"/>
                    </div>
                </a>
                <a href="https://twitter.com/cdreamsorg" target="_blank">
                    <div className="w-[80px] h-[80px] rounded-[50%] bg-[#CEFCFA] flex items-center justify-center">
                        <img src={xIcon} alt="Link to Twitter Account" className="h-[50px]"/>
                    </div>
                </a>
                <a href="https://www.linkedin.com/company/community-dreams-foundation/" target="_blank">
                    <div className="w-[80px] h-[80px] rounded-[50%] bg-[#CEFCFA] flex items-center justify-center">
                        <img src={linkedinIcon} alt="Link to Linked in Account" className="h-[50px]"/>
                    </div>
                </a>
            </div>
        </div> 
        <form className="flex flex-col justify-center flex-1 items-baseline gap-[15px]">
            <h2 className="text-[35px] font-bold text-left"> Join Newsletter</h2>
            <label htmlFor="news-letter-email" className="text-left text-[33px] text-bold md:w-[30vw] w-[100%]"> Sign up and get the latest news and updates.</label>
            <input id="news-letter-email" placeholder="Your Email" className="max-w-[500px] w-[30vw] bg-[#43CCC4] bg-gray-100 text-[30px] p-[10px] pl-[20px] rounded-[30px]"/>
            <button type="submit" className="font-bold p-[10px] w-[30vw] max-w-[500px] bg-[#43CCC4] text-[30px] text-white text-bold p-[10px] pl-[20px] rounded-[30px]"> Subscribe </button>
        </form>
    </div>
        <div className="pl-[20px] pr-[40px] mt-[35px] flex justify-between mb-[30px]">
            <footer className="text-[25px] font-bold w-[35vw] max-w-[700px] text-left"> 2024 Community Dreams Foundation 501c3 Certified. All rights reserved.</footer>
            <div className="flex items-center gap-[30px] flex-col md:flex-row">
                <a href="/FAQ" className="text-[25px] font-bold"> FAQ</a>
                <a href="/Privacy" className="text-[25px] font-bold"> Privacy</a>
                <a className="text-[25px] font-bold"> Terms & Conditions </a>
            </div>
        </div>
    </div>
}
