import DreamLineLogo from "../../assets/images/dreamlineai-logo-simple.png";
import { useNavigate } from "react-router-dom";
import instagramIcon from "../../assets/images/SocialIcons/Instagram.png";
import linkedInIcon from "../../assets/images/SocialIcons/LinkedIn.png";
import facebookIcon from "../../assets/images/SocialIcons/Facebook.png";
import twitterXIcon from "../../assets/images/SocialIcons/TwitterX.png";
import MainFooter from "../../assets/components/js/MainFooter";
import MainHeader from "../../assets/components/js/MainHeader";

export const Incentives = () => {
  const navigate = useNavigate();

  return (
    <div className="revised-home flex flex-col h-screen">
      <MainHeader />

      <div className="solutions-overview-container">
        <section className="solutions-introduction">
          <p>
            Dreamline AI is committed to making energy efficiency upgrades not
            only accessible but also financially advantageous for property
            owners. Our platform streamlines the entire process of identifying,
            applying for, and deploying incentives, ensuring that you maximize
            the financial benefits available to you.
          </p>
        </section>
        <section className="solutions-section">
          <h2>
            <strong>Seamless Incentive Identification and Deployment</strong>
          </h2>
        </section>
        <section className="solutions-section">
          <h2>Comprehensive Database of Incentives</h2>
          <p>
            <strong>Extensive Database:</strong> Our platform leverages a robust
            database containing over 10,000 incentives, including grants,
            rebates, tax credits, and financing solutions from both public and
            private sectors.
          </p>

          <p>
            <strong>Machine Learning Algorithms:</strong> Our advanced machine
            learning algorithms rapidly analyze your property’s data to
            determine eligibility for various incentives. This ensures you are
            matched with the most relevant and beneficial programs.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Effortless Application Process</h2>
          <p>
            <strong>Automated Eligibility Checks:</strong> Our system
            automatically checks eligibility criteria against our extensive
            database, significantly reducing the time and effort required to
            identify suitable incentives.
          </p>

          <p>
            <strong>Pre-Filled Applications:</strong> By integrating with
            property data and historical information, our platform pre-fills
            application forms, streamlining the submission process and reducing
            the likelihood of errors.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Rapid Incentive Deployment</h2>
          <p>
            <strong>Direct Partnerships:</strong> We partner directly with
            incentive providers to facilitate faster approval and disbursement
            of funds. This close collaboration allows us to design more
            effective incentive programs and encourages rapid deployment.
          </p>

          <p>
            <strong>Streamlined Processes:</strong> Our platform is designed to
            eliminate bottlenecks, ensuring that once incentives are approved,
            they are deployed quickly and efficiently, minimizing delays and
            disruptions to your projects.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Customized Financing Solutions</h2>
          <p>
            <strong>Flexible Options:</strong> In addition to grants and
            rebates, our platform offers access to a variety of financing
            solutions tailored to meet the specific needs of property owners.
            Whether you require low-interest loans, on-bill financing, or other
            financial products, we have options to suit your needs.
          </p>

          <p>
            <strong>Integrated Support:</strong> Our team provides comprehensive
            support throughout the financing process, from initial application
            to final disbursement, ensuring a smooth and hassle-free experience.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Maximizing Financial Benefits</h2>
          <p>
            <strong>Optimized Strategies:</strong> By combining multiple
            incentives and financing options, we help property owners develop
            optimized strategies for funding their energy efficiency projects.
            This approach maximizes financial returns and reduces upfront costs.
          </p>

          <p>
            <strong>Continuous Updates:</strong> Our platform continuously
            updates its database with new incentives and changes to existing
            programs, ensuring that you always have access to the most current
            and advantageous opportunities.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Transparent Reporting</h2>
          <p>
            <strong>Detailed Tracking:</strong> We provide detailed tracking of
            all incentives applied for and received, offering transparent
            reporting that keeps you informed about the financial aspects of
            your projects.
          </p>

          <p>
            <strong>Impact Analysis:</strong> Our reporting includes impact
            analysis, showing how incentives contribute to overall project
            savings and financial performance.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Benefits of Using Dreamline AI for Incentives</h2>
          <p>
            <strong>Financial Savings:</strong> Access to a wide range of
            incentives can significantly reduce the cost of energy efficiency
            upgrades.
          </p>

          <p>
            <strong>Time Efficiency:</strong> Automated processes and
            streamlined workflows save time and reduce the administrative burden
            on property owners.
          </p>

          <p>
            <strong>Increased Project Viability:</strong> Clear and detailed
            reporting provides property owners with a comprehensive
            understanding of their financial benefits and project impact.
          </p>

          <p>
            <strong>Enhanced Transparency:</strong> By reducing your property’s
            energy consumption and carbon footprint, you contribute to a more
            sustainable future.
          </p>
        </section>
        <section className="solutions-section">
          <p>
            Dreamline AI makes it easier than ever to take advantage of the
            numerous incentives available for energy efficiency projects. Our
            seamless platform and expert support ensure that you can focus on
            improving your property while we handle the complexities of
            incentive management. Contact us today to learn more about how we
            can help you maximize your financial benefits and achieve your
            energy efficiency goals.
          </p>
        </section>
      </div>

      <MainFooter />
    </div>
  );
};
