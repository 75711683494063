import assessmentCloseOut from "../../images/energy-assessment-X.png"
import { AssessmentAddress } from "./AssessmentAddress"
import { Calender } from "./Calender"
import { useState, useEffect, useRef } from "react"
import { TimeZone } from "./TimeZone"
import { useNavigate } from "react-router-dom"

 

export const ScheduleAssessmentPopUp = (props) => {


    const [selectedDay, setSelectedDay] = useState(null)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
    const [selectedTimeZone, setSelectedTimeZone] = useState()
    const [note, setNote] = useState(null)
    const [addresses, setAddresses] = useState([])
    const [submitErrors, setSubmitErrors] = useState(null)
    const [addressBeingAdded, setAddressBeingAdded] = useState(false)
    const fetchedAddressRef = useRef()
    const selectedAddressRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        //insert fetch request for this user's addresses here.

        //dummy data for fetch request
        // setAddresses([{
        //     address_1: "123 Dreamline Lane",
        //     address_2: null,
        //     address_3: null,
        //     city: "Cleveland",
        //     state: "OH",
        //     country: "United States",
        //     postal_code: 44114
        // },
        // {
        //     address_1: "123 Dreamline Lane",
        //     address_2: null,
        //     address_3: null,
        //     city: "Cleveland",
        //     state: "OH",
        //     country: "United States",
        //     postal_code: 44114
        // }])

        const token = localStorage.getItem('token');
        if (!token) {
            navigate("/signin")
        }
        
    }, [])

    useEffect(() => {
        fetchAddresses()
    }, [])

    const fetchAddresses = async () => {
       
        const addressGetURL = `${process.env.REACT_APP_SERVER_URL}/address/getAllAddressesByUID`
        const token = localStorage.getItem('token');
        try {
            return await fetch(addressGetURL, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
            }).then(async (response) => {
                
                if (response.status == 403) {
                    navigate("/signin")
                }

                let responseData = await response.json(); // acquire response data
                
                
                let array = []
                

                responseData.userAddress.forEach((element, index) => {
                    array[index] = {
                        address_1: element.address_1,
                        address_2: element.address_2,
                        address_3: element.address_3,
                        city: element.city,
                        state: element.state,
                        country: element.country,
                        postal_code: "" + element.postal_code
                    }
                });

                fetchedAddressRef.current = array;

                setAddresses(array)
                
                return responseData;
                
            }).catch((error) => {
                console.error(error) //console.log any errors
            });

        }
        catch (e) {
            console.log(e)
        }
    }

    const ensureTwoDigits = (num) => {
        return num < 10? '0' + num : num; 
    }

    const addOneMonth = (dateString) => {
        let [date, time] = dateString.split(" ")
        let month = date.split("-")[1]
        let monthNum = parseInt(month) + 1;
        date = date.split("-")[0] + "-" + ensureTwoDigits(monthNum) + "-" + date.split("-")[2]
        time = time.split(".")[0]
        return date + " " + time
    }

    const compareAddresses = (address1, address_2) => {
        return (
            address1.address_1 === address_2.address_1 &&
            address1.address_2 === address_2.address_2 &&
            address1.address_3 === address_2.address_3 &&
            address1.city === address_2.city &&
            address1.state === address_2.state &&
            address1.country === address_2.country &&
            address1.postal_code + "" === address_2.postal_code+ ""
        );
    }

    const checkForAddressExistence = async () => {
        let isFetched = false;
        fetchedAddressRef.current.forEach((element) => {
            if (compareAddresses(element, addresses[selectedAddressRef.current])) {
                isFetched = true;
            }
        })
        const token = localStorage.getItem('token');

        //if it is not fetched, add the address
        if (!isFetched) {
            
            const addressPostURL = `${process.env.REACT_APP_SERVER_URL}/address/create-address`
            try {
                await fetch(addressPostURL, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                        }
                    ,
                    body: JSON.stringify( addresses[selectedAddressRef.current] )
                }).then(async (response) => {
                    let responseData = await response.json()
                    console.log("Address Created: ", responseData)
                })
            }
            catch (e) {
                console.log("Error: " + e)
            }
        }
    }

    const submitAssessment = async () => {

        //errors array to look for any errors in submitting the form
        
        let errors = [];

        if (!selectedDay) {
            errors.push("Please Select a date for this assessment on the calender.");
        }
        if (!selectedTimeSlot) {
            errors.push("Please select a time slot for this assessment");
        }
        if (selectedAddressRef.current == null | selectedAddressRef.current >= addresses.length) {
            errors.push("Please select a valid address for this assessment");
        }

        if (errors.length > 0) {
            setSubmitErrors(errors)
            return
        } 

        const token = localStorage.getItem('token');
        //check each fetched address to see if the selected address is one of them.
        await checkForAddressExistence()

        let newAddresses = await fetchAddresses()
        newAddresses = newAddresses.userAddress
        
        let address_id = null;
        newAddresses.forEach((item) => {
            if (compareAddresses(item, addresses[selectedAddressRef.current])) {
                address_id = item.address_id
            }
        })

        const postURL = `${process.env.REACT_APP_SERVER_URL}/timeslot/create-timeslot`
        try {
            await fetch(postURL, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    note: note,
                    dateTime: addOneMonth(selectedTimeSlot),
                    addressId: address_id
                })
                
            }).then(async (response) => {
                let responseData = await response.json(); // acquire response data

                console.log("Time Slot Created: ", responseData)
            }).catch((error) => {
                errors.push("Error reserving time slot. Please Try Again Later")
            });
        }
        catch (e) {
            errors.push("Error reserving time slot. Please Try Again Later")
        }

        if (errors.length > 0) {
            setSubmitErrors(errors)
            return
        }

        //send post request for appointment here

        if (errors.length > 0) {
            return;
        }
        
        //for sending data to the completion component of the pop-up
        let [first, second] = selectedTimeSlot.split(" ");
        const [year, month, day] = first.split("-")
        const [hour, minute, seconds] = second.split(":")
        
 
        props.setScheduleInfo({
            date: new Date(Date.UTC(year, ensureTwoDigits(parseInt(month)), day, hour, minute, seconds)),
            timeZone: selectedTimeZone.timeZone,
            timeZoneName: selectedTimeZone.timeZoneName
        })
        
        document.querySelector(".schedule-completion").classList.remove("hidden");
        document.querySelector(".schedule-assessment-page").classList.add("hidden");
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        //to see if it is a fetched address
        

        
    }



    return <div className="schedule-assessment-page hidden w-[80vw] self-center mt-[70px] mb-[70px]">
        <div className="top-section p-[50px] flex flex-col ">
            <div className=" mb-[30px] title-and-close flex items-center justify-between">
                <h1 className="font-bold text-[20px] text-left m-[0px]"> Schedule your Energy Assessment</h1>
                <button onClick={() => {
                    document.querySelector(".schedule-assessment-page").classList.add("hidden")
                    document.querySelector(".overlay-gray").classList.add("hidden")
                }}> <img src={assessmentCloseOut} alt="Close out of Pop up"/></button>
            </div>
            <p className="text-left">Book your energy assessment now, and our trusted experts will visit your address to ensure a thorough and
                personalized evaluation—putting you on the path to energy efficiency.</p>
        </div>
        <div className="schedule-section pl-[50px] pr-[50px]" style={{
            borderBottom: "1px solid gray"
        }}>
            <div className="calender-and-timezone flex screen1000:flex-row flex-col mb-[50px] gap-[20px] items-center justify-between">
                <div className="flex-1 flex flex-col">
                    <h1 className="text-left mb-[20px]"> Select a date and time</h1>
                    <Calender setSelectedDate={setSelectedDay}/>
                </div>
                <div className="flex-1 h-[390px]">
                    <TimeZone selectedDay={selectedDay} setSelectedTimeZone={setSelectedTimeZone} changeTimeSlot={setSelectedTimeSlot} setNote={setNote}/>
                </div>
            </div>

        </div>
        <div className="address-section flex flex-col items-baseline p-[30px] pl-[50px] pr-[50px] " style={{
            borderBottom: "1px solid gray"
        }}>
            <h1 className="mb-[30px]"> Address </h1>
            <div className="address-list flex flex-col gap-[10px] w-[100%]">
                {addresses && addresses.map((item, index) => {
                    return <AssessmentAddress key={index} setAddressBeingAdded={setAddressBeingAdded} address={addresses} addressIndex={index} changeAddress={setAddresses} selectedAddress={selectedAddressRef} />
                })}
            </div>
            {!addressBeingAdded && <button className="mt-[20px] text-[#43CCC4]" onClick={() => {
                let addressList = [...addresses];
                addressList.push(
                    {
                        address_1: null,
                        address_2: null,
                        address_3: null,
                        city: null,
                        state: null,
                        country: null,
                        postal_code: null,
                        startEditing: true
                    } 
                )

                setAddresses(addressList)
            }} > + Add a new address</button> } 
        </div>
        <div className="cancel-or-submit-buttons flex items-center justify-end p-[50px] pb-[30px] pt-[30px]">
            <button className="cancel-button-assessment-page" onClick={() => {
                document.querySelector(".schedule-assessment-page").classList.add("hidden")
                document.querySelector(".overlay-gray").classList.add("hidden")
            }}> Cancel </button>
            <button className="submit-button-assessment-page" onClick={() => {
                submitAssessment()
            }}> Submit</button>
        </div>
        <div className="submit-errors flex flex-col pr-[50px] pb-[20px]">
            {submitErrors && submitErrors.map((item, index) => {
                return <p key={index} className="text-red-500 self-end">{item}</p>
            })}
        </div>
    </div>
}
