import { useEffect, useState } from "react"
import scheduledPNG from "../../images/Clock_Checked.png"
import assessmentCloseOut from "../../images/energy-assessment-X.png"
import CalenderIcon from "../../images/CalenderVector.png"
import timeZoneGlobe from "../../images/Timezone-Globe.png"


export const ScheduleCompletion = (props) => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    
    const [name, setName] = useState();
    const [time, setTime] = useState()
    useEffect(() => {

        if (!props.scheduleInfo) {
            return;
        }

        setName(localStorage.getItem('firstName'))

        let intlwithMinutes = new Intl.DateTimeFormat('en-US', {
            hour: "numeric",
            minute: "numeric",
            timeZone: props.scheduleInfo.timeZone
        })


        let date = props.scheduleInfo.date
        let timeVar = {
            date: date.getDate(),
            day: days[date.getDay()],
            month: months[date.getMonth()],
            year: date.getFullYear(),
            timeZone: props.scheduleInfo.timeZone,
            startTime: intlwithMinutes.format(date),
            endTime: intlwithMinutes.format(new Date(date.setHours(date.getHours() + 1))),
            timeZoneName: props.scheduleInfo.timeZoneName.fullName,
        }
        
        setTime(timeVar)
    }, [props])


    if (!props.scheduleInfo) {
        return<div className="schedule-completion hidden"></div>
    }
    return <div className="self-center mt-[50px] schedule-completion max-w-[800px] flex flex-col min-h-[500px] pr-[50px] pl-[50px] rounded-[35px]" style={{
        backgroundColor: "white",
    }}>
        <button onClick={() => {
            document.querySelector(".schedule-completion").classList.add("hidden")
            document.querySelector(".overlay-gray").classList.add("hidden")
            window.location.reload(true)
        }} className="self-end mr-[30px] mt-[30px]"> <img src={assessmentCloseOut} alt="Close out of Pop up"/></button>
        <div className="top-section flex items-center justify-center ">
            <img src={scheduledPNG} alt="Clock checked image" />
            <h1 className="text-[30px] font-bold w-[350px] "> Congratulations {name}, You're Scheduled!</h1>
        </div>
        <h1 className="text-[18px] mt-[20px] mb-[20px] self-center"> A calendar invitation has been sent to your email address. </h1>
        <div className="flex flex-col self-center rounded-[20px] p-[30px] gap-[15px] congratulations-box">
            <h1 className="text-left text-[25px] font-bold"> Energy Assessment</h1>
            <div className="flex items-center justif-between gap-[12px]">
                <img src={CalenderIcon} alt="Date icon"/>
                <p> {time?.day}, {time?.month} {time?.date} {time?.year}, {time?.startTime} - {time?.endTime}</p>
            </div>
            <div className="flex items-center justif-between gap-[10px]">
                <img className="h-[30px]" src={timeZoneGlobe} alt="Date icon"/>
                <p> {time?.timeZoneName} -  ({time?.startTime})</p>
            </div>
        </div>
        <h1 className="text-[15px] mt-[20px] mb-[20px] self-center"> An email will be soon sent to you with the details of our trusted inspector responsible for the assessment.</h1>
        <div className="buttons mb-[50px] flex item justify-end">
            <button className="open-invitation-button selft-right" disabled> Open Invitation </button>
            <button className="done-button self-right" onClick={() => {
                document.querySelector(".schedule-completion").classList.add("hidden")
                document.querySelector(".overlay-gray").classList.add("hidden")
                window.location.reload(true)
            }} > Done </button>
        </div>
    </div>
}
