import { Routes, Route, Navigate, Link } from "react-router-dom";

import { SignUp } from "../pages/Signup/SignUp";
import { PreRegister } from "../pages/Signup/PreRegister";
import { SignUpProfileSelection } from "../pages/Signup/ProfileSelection/ProfileSelection";
import { SignIn } from "../pages/Signin/SignIn";
import { UserIncentive } from "../pages/Userincentive/UserIncentive";
import { PageNotFound } from "../pages/PageNotFound";
import { HomeownerP } from "../pages/HomeownerPersonal/HomeownerP";
import { AccountVerification } from "../pages/Signin/AccountVerification.js"
import { InstituteInfo } from "../pages/Instituteinfo/InstituteInfo.js"
import { Assessment } from "../pages/Home/Assessment.js";
import { ProjectCategory } from "../pages/Signup/ProjectCategory/ProjectCategory.js";
import { About } from "../pages/Home/About.js";
import { UserProfileReason } from "../pages/Signup/UserProfileReason/UserProfileReason.js";
import { Solutions } from "../pages/Home/Solutions.js";
import { Incentives } from "../pages/Home/Incentives.js";
import { ProjectManagement } from "../pages/Home/ProjectManagement.js";
import { EnergyAssessments } from "../pages/Home/EnergyAssessments.js";
import { IncentiveDeployment } from "../pages/Home/IncentiveDeployment.js";

import { Home } from "../pages/LandingPage/Home.js";
import { PropertyOwners } from "../pages/LandingPage/PropertyOwners.js";
import { IncentiveProviders } from "../pages/LandingPage/IncentiveProviders.js";
import { OurStory } from "../pages/LandingPage/OurStory.js";
import { MySustainabilityApp } from "../pages/LandingPage/MySustainabilityApp.js";
import { Careers } from "../pages/LandingPage/Careers.js";

export const AllRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Navigate to='/' replace />} />
                <Route path="/property-owners" element={<PropertyOwners />} />
                <Route path="/incentive-providers" element={<IncentiveProviders />} />
                <Route path="/our-story" element={<OurStory />} />
                <Route path="/my-sustainability-app" element={<MySustainabilityApp />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/pre-register" element={<PreRegister />} />

                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/userincentive" element={<UserIncentive />} />
                <Route path="/accountverification" element={<AccountVerification />} />
                <Route path="/instituteinfo" element={<InstituteInfo />} />
                <Route path="/signup-profileselection" element={<SignUpProfileSelection />} />
                <Route path="/projectcategory" element={<ProjectCategory />} />
                <Route path="/about" element={<About />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/incentives" element={<Incentives />} />
                <Route path="/incentive-deployment" element={<IncentiveDeployment />} />
                <Route path="/energy-assessments" element={<EnergyAssessments />} />
                <Route path="/project-management" element={<ProjectManagement />} />
                <Route path="/energy-assessment-home" element={<Assessment />} />
                <Route path="/homeownerpersonal" element={<HomeownerP />} />
                <Route path="/userprofilereason" element={<UserProfileReason />} />

                <Route path="*" element={<Home />} />
            </Routes>
        </div>
    );
}
