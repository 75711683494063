import React from 'react';
import { Link } from 'react-router-dom';
import instagramIcon from '../../../assets/images/SocialIcons/Instagram.png';
import twitterXIcon from '../../../assets/images/SocialIcons/TwitterX.png';
import linkedInIcon from '../../../assets/images/SocialIcons/LinkedIn.png';
import facebookIcon from '../../../assets/images/SocialIcons/Facebook.png';
import DreamLineLogo from "../../images/dreamline_logo.png";

const MainFooter = () => {
    return (
        <div className="bg-[#f0f7ff] sm:px-24 py-8">
            <div className="flex flex-col lg:flex-row justify-between items-start text-gray-800">
                <div className="flex flex-col gap-2 mb-6 lg:mb-0 items-start hidden lg:block">
                    <span className="flex font-bold">Property Owners</span>
                    <ul className="flex flex-col gap-2 items-start">
                        <li> <Link to="/energy-assessments">Energy Assessments</Link></li>
                        <li> <Link to="/incentives">Incentives</Link></li>
                        <li> <Link to="/project-management">Project Management</Link></li>
                    </ul>
                </div>
                <div className="flex flex-col gap-2 mb-6 lg:mb-0 items-start hidden lg:block">
                    <span className="flex font-bold">Incentive Providers</span>
                    <ul className="flex flex-col gap-2 items-start">
                        <li> <Link to="/incentive-deployment">Incentive Deployment</Link></li>
                        <li> <Link to="/">Project Management</Link></li>
                        <li> <Link to="/">Impact Reporting</Link></li>
                    </ul>
                </div>
                <div className="flex flex-col gap-2 mb-6 lg:mb-0 items-start hidden lg:block">
                    <span className="flex font-bold">About</span>
                    <ul className="flex flex-col gap-2 items-start">
                        <li> <Link to="/our-story">Our Story</Link></li>
                        <li> <Link to="/solutions">Solutions</Link></li>
                        <li> <Link to="/careers">Careers</Link></li>
                    </ul>
                </div>
                <div className="flex flex-col gap-2 mb-6 lg:mb-0 items-start hidden lg:block">
                    <span className="flex font-bold">Contact Us</span>
                    <p>info@dreamlineai.com</p>
                </div>
            </div>
            <div className="flex justify-between mt-8">
                <img src={DreamLineLogo} alt="dream line logo image" className="h-16" />
                <div className="flex flex-col items-start">
                    <div className="font-bold items-start">Follow Us:</div>
                    <div className="icons flex space-x-4 items-start">
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={facebookIcon} alt="Facebook" className="w-10 h-10 bg-[#bfdba9]" />
                        </a>
                        <a href="https://www.instagram.com/cdreamsorg/" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className="w-10 h-10 bg-[#bfdba9]" />
                        </a>
                        <a href="https://twitter.com/cdreamsorg" target="_blank" rel="noopener noreferrer">
                            <img src={twitterXIcon} alt="Twitter" className="w-10 h-10 bg-[#bfdba9]" />
                        </a>
                        <a href="https://www.linkedin.com/company/community-dreams-foundation" target="_blank" rel="noopener noreferrer">
                            <img src={linkedInIcon} alt="LinkedIn" className="w-10 h-10 bg-[#bfdba9]" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="w-full text-center mt-8">
                <p className="text-xs">Copyright © 2022 Dreamline AI. All rights reserved. Unauthorized use or reproduction of content is strictly prohibited. Dreamline AI is a registered trademark of Community Dreams. For inquiries, contact info@dreamlineai.com</p>
            </div>
        </div>
    );
}

export default MainFooter;
