import React from 'react';
import logo from '../../images/logo.png'
import groupPic from '../../images/group-pic.png';

const CommonLayout = ({ children}) => {
    return (
        <div
            className="flex min-h-screen bg-no-repeat"
            style={{
                backgroundImage: `url(${groupPic})`,
                minHeight: '100vh',
                width: '80%',
                margin: '20px auto',
            }}
        >
            <div className="w-5/6  p-8  my-14 mx-auto rounded-lg">
                <div className="w-full md:max-w-lg md:min-w-0  bg-white bg-opacity-90 p-10 rounded-l-lg mt-14 ml-auto">
                    <img className="mr-4" src={logo} alt="Logo"/>
                    <br />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CommonLayout;
