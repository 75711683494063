import DreamLineLogo from "../../assets/images/dreamlineai-logo-simple.png"
import { useNavigate } from "react-router-dom"
import instagramIcon from "../../assets/images/SocialIcons/Instagram.png"
import linkedInIcon from "../../assets/images/SocialIcons/LinkedIn.png"
import facebookIcon from "../../assets/images/SocialIcons/Facebook.png"
import twitterXIcon from "../../assets/images/SocialIcons/TwitterX.png"
import '../../assets/style/About.css'
import MainHeader from "../../assets/components/js/MainHeader"
import MainFooter from "../../assets/components/js/MainFooter"

export const About = () => {

        const navigate = useNavigate()

        return <div className="revised-home flex flex-col h-screen">
                <MainHeader />

                <div className="about-section-list">
                        <div className="about-section">
                                <h2>Vision to Revolutionize</h2>
                                <p>
                                        Dreamline AI was born from a vision to revolutionize the way capital is deployed for
                                        energy-efficient and clean energy projects. In a world increasingly aware of the urgent need for
                                        sustainable solutions, our founders recognized the barriers that often hinder the swift
                                        deployment of resources where they are most needed. Thus, Dreamline AI was established with
                                        a mission to streamline these processes, making it easier and faster to fund and manage
                                        impactful energy initiatives.
                                </p>
                        </div>
                        <div className="about-section">
                                <h2>Mission to Streamline Processes</h2>
                                <p>Our journey began with a dedicated team of experts in energy, finance, and technology who
                                        shared a common goal: to reduce the complexities and delays associated with traditional
                                        funding and project management. By leveraging advanced artificial intelligence and innovative
                                        technologies, we developed a platform that accelerates incentive deployment, optimizes project
                                        management, and provides detailed, real-time impact reporting.
                                </p>
                        </div>
                        <div className="about-section">
                                <h2>Dedicated Team of Experts</h2>
                                <p>
                                        Dreamline AI stands out by offering solutions that break down the traditional barriers to
                                        investment in energy efficiency and renewable projects. Our platform simplifies the application
                                        and approval processes, ensuring rapid deployment of incentives and resources. This not only
                                        speeds up project timelines but also maximizes the positive impact on communities and the
                                        environment.
                                </p>
                        </div>
                        <div className="about-section">
                                <h2>Innovation</h2>
                                <p>
                                        At Dreamline AI, our story is one of innovation, commitment, and a relentless pursuit of a
                                        sustainable future. We are proud to play a pivotal through, driving the transition to a cleaner, more
                                        efficient energy landscape. Join us as we continue to break down barriers, accelerate progress,
                                        and create lasting positive change for our planet and future generations.
                                </p>
                        </div>
                </div>

                <MainFooter />
        </div>
}
