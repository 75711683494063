import DreamLineLogo from "../../assets/images/dreamlineai-logo-simple.png";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import instagramIcon from "../../assets/images/SocialIcons/Instagram.png";
import linkedInIcon from "../../assets/images/SocialIcons/LinkedIn.png";
import facebookIcon from "../../assets/images/SocialIcons/Facebook.png";
import twitterXIcon from "../../assets/images/SocialIcons/TwitterX.png";
import MainHeader from "../../assets/components/js/MainHeader";
import MainFooter from "../../assets/components/js/MainFooter";
import "../../assets/style/Solutions.css";

export const EnergyAssessments = () => {
  const navigate = useNavigate();

  return (
    <div className="revised-home flex flex-col h-screen">
      <MainHeader />

      <div className="solutions-overview-container">
        <section className="solutions-introduction">
          <p>
            At Dreamline AI, we are dedicated to helping property owners
            optimize their buildings for energy efficiency and sustainability.
            Our comprehensive energy assessment process ensures that your
            property not only reduces its environmental impact but also improves
            comfort, safety, and accessibility for all occupants.
          </p>
        </section>
        <section className="solutions-section">
          <h2>
            <strong>Comprehensive Energy Assessment Process</strong>
          </h2>
          <p>
            Our energy assessment process is designed to provide a thorough
            evaluation of your property’s energy performance.
          </p>
          <br></br>
          <p>Here's how we achieve this:</p>
        </section>
        <section className="solutions-section">
          <h2>Initial Consultation</h2>
          <p>
            Local Dreamline Representative: A local Dreamline representative
            will visit your property to understand your specific needs and
            objectives. This personalized approach ensures that we tailor our
            assessment to your property’s unique characteristics.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Detailed Inspection and Analysis</h2>

          <p>
            <strong>Weatherization:</strong> We evaluate the building envelope,
            including insulation, windows, and doors, to identify opportunities
            for reducing heat loss and improving thermal comfort.
          </p>

          <p>
            <strong>Electrification:</strong> Our team assesses the potential
            for electrification upgrades, such as switching from fossil
            fuel-based systems to electric heating, cooling, and appliances.
            This step is crucial for reducing carbon emissions and enhancing
            energy efficiency.
          </p>

          <p>
            <strong>Accessibility Upgrades:</strong> We consider modifications
            that can improve accessibility, ensuring that your property is
            inclusive and comfortable for all occupants, including those with
            disabilities.
          </p>

          <p>
            <strong>iSecurity Enhancements:</strong> Our assessment also
            includes a review of security systems and measures, identifying
            improvements that can enhance the safety and security of your
            property.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Data Collection and Monitoring</h2>
          <p>
            <strong>Advanced Tools and Technology:</strong> Using
            state-of-the-art tools and technology, we collect detailed data on
            your property’s energy usage, thermal performance, and potential
            areas for improvement.
          </p>

          <p>
            <strong>Granular Impact Reporting:</strong> We provide precise and
            transparent reporting, giving you a clear picture of your property’s
            current energy performance and the expected impact of recommended
            upgrades.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Customized Recommendations</h2>
          <p>
            Based on our findings, we develop a set of tailored recommendations
            that address the specific needs of your property. These
            recommendations are designed to maximize energy savings, enhance
            comfort, and improve overall building performance.
          </p>
        </section>
        <section className="solutions-section">
          <h2>Benefits of Dreamline Energy Assessments</h2>
          <p>
            <strong>Reduced Energy Costs:</strong> Our assessments identify
            cost-effective measures to lower your energy bills.
          </p>

          <p>
            <strong>Enhanced Property Value:</strong> Energy-efficient upgrades
            can increase the market value of your property.
          </p>

          <p>
            <strong>Improved Comfort and Safety:</strong> Weatherization,
            electrification, and security enhancements contribute to a more
            comfortable and secure living environment.
          </p>

          <p>
            <strong>Environmental Impact:</strong> By reducing your property’s
            energy consumption and carbon footprint, you contribute to a more
            sustainable future.
          </p>
        </section>
        <section className="solutions-section">
          <p>
            Partner with Dreamline AI for your energy assessment and take the
            first step towards a more efficient, sustainable, and valuable
            property. Contact us today to schedule your comprehensive energy
            assessment.
          </p>
        </section>
      </div>

      <MainFooter />
    </div>
  );
};
