import React from 'react'
import { Link } from 'react-router-dom';

import upgradeProperty from '../../assets/images/upgradeYourProperty.jpeg';
import energyAssessment from '../../assets/images/energy_assessment.svg';
import manageProjects from '../../assets/images/manage_projects.svg';
import earnIncentives from '../../assets/images/earn_incentives.svg';
import commercialResidentialLoading from '../../assets/images/commercialResidentialProperty.jpeg';
import personalizedIncentive from '../../assets/images/personalizedIncentives.jpeg';
import propertyManagement from '../../assets/images/propertyManagement.jpeg';
import sustainableTomorrow from '../../assets/images/sustainableTomorrow.jpeg';
import energyCosts from '../../assets/images/energy_costs.svg';
import propertyValue from '../../assets/images/property_value.svg';
import comfortSafety from '../../assets/images/comfort_safety.svg';
import environmentalImpact from '../../assets/images/environmental_impact.svg';

import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';
import { LearnMoreButton } from '../../assets/components/js/navigation/LearnMoreButton';

export const PropertyOwners = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col item-center pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-8 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-4 xl:gap-y-6'>
          <div className='relative w-full h-90 overflow-hidden rounded-[30px]'>
            <p className='absolute w-full top-[164px] h-18 xl:top-[132px] xl:h-12 text-white font-semibold text-2xl xl:text-3.5xl z-10'>
              Upgrade Your Property.<br />
              Support a Sustainable Future.
            </p>
            <img
              className='absolute top-0 lg:top-[-284px] w-full h-full lg:h-[746px] object-cover lg:object-fill'
              src={upgradeProperty}
              alt='Header'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-30'></div>
          </div>
          <p className='max-w-[927px] text-base lg:text-xl xl:text-2xl'>
            Dreamline AI is your digital tool kit all the way from initial property assessment, securing incentives, and managing your upgrade projects.
          </p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-4 lg:gap-y-2'>

          <p className='lg:col-span-3 w-full h-8 xl:h-full font-bold text-center text-2xl xl:text-3.5xl'>
            How it works:
          </p>

          <div className='flex flex-col items-start gap-y-2 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={energyAssessment}
                  alt='sweet home'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Energy Assessment</p>
                <p className='w-full xl:w-88 text-base'>
                  Our experts will evaluate your property's weatherization, electrical system, accessibility, and security at no cost. We then provide a comprehensive checklist of recommended upgrades.
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/energy-assessments' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-2 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={earnIncentives}
                  alt='earn incentives'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Earn Incentives</p>
                <p className='w-full xl:w-88 text-base'>
                  With Dreamline, you get access to incentives such as grants, loans, rebates, tax credits, and more! We rapidly identify these incentives and ensure an easy application process.
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/incentives' text='Learn More' /> */}
          </div>

          <div className='flex flex-col items-start gap-y-2 xl:gap-y-6'>
            <div className='w-full flex flex-col gap-y-2'>
              <div className='w-full xl:w-88 h-54 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-38 object-contain'
                  src={manageProjects}
                  alt='manage projects'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='font-semibold text-2xl'>Manage Projects</p>
                <p className='w-full xl:w-88 text-base'>
                  We manage the heavy lifting. Choose from trusted local contractor while our expert project managers and our smart software streamlines your property dreams!
                </p>
              </div>
            </div>
            {/* <LearnMoreButton link='/project-management' text='Learn More' /> */}
          </div>

        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2'>
          <div className='relative w-full h-90 xl:w-136 xl:h-120'>
            <img
              className='absolute w-full h-full rounded-[30px] object-cover'
              src={commercialResidentialLoading}
              alt='city map'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-30 rounded-[30px]'></div>
          </div>
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Commercial or Residential Property, We're Here To Help.</p>
            <p className='text-base'>
              At Dreamline, we help all property owners, from commercial to residential, access top incentives, trusted contractors, and a brighter future. Our platform offers the resources and support you need to achieve your property goals. Join us in building a better tomorrow, one project at a time.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2'>
          <div className='relative w-full h-90 xl:w-136 xl:h-120 lg:order-last'>
            <img
              className='absolute w-full h-full rounded-[30px] object-cover'
              src={personalizedIncentive}
              alt='person working on laptop'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-15 rounded-[30px]'></div>
          </div>
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Prosper with personalized incentives for you</p>
            <div className='flex flex-col items-start gap-y-6'>
              <p className='text-base'>
                Dreamline's Incentives Portal gives you exclusive access to the best incentives tailored just for you. Our smart software, backed by our deep understanding of communities, ensures we find the perfect fit for your needs. Seamlessly apply, monitor status, and enjoy hassle-free acquisition.
              </p>
              {/* <LearnMoreButton link={'/incentives-portal'} text={'Learn More'}/> */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2'>
          <img
            className='w-full h-90 xl:w-136 xl:h-120 rounded-[30px] object-cover'
            src={propertyManagement}
            alt='working person'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Your One-Stop Shop for Property Management</p>
            <div className='flex flex-col items-start gap-y-6'>
              <p className='text-base'>
                Dreamline has got you covered with trusted contractors, streamlined processes, and expert guidance every step of the way. Whether you're starting a new project or wrapping up an old one, Dreamline ensures your dreams become priorities and your projects reach completion. It's time to turn your vision into reality with Dreamline by your side
              </p>
              {/* <LearnMoreButton link={'/property-management'} text={'Learn More'}/> */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2'>
          <img
            className='w-full h-90 xl:w-136 xl:h-120 rounded-[30px] object-cover lg:order-last'
            src={sustainableTomorrow}
            alt='happy family'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>A Sustainable Tomorrow Starting at Home</p>
            <div className='flex flex-col items-start gap-y-4 xl:gap-y-6'>
              <p className='text-base'>
                With Dreamline, sustainability is not only accessible but also enjoyable. Our platform offers interactive games that teach eco-friendly habits in a fun and engaging way. From recycling to energy conservation, each game empowers you to make a positive impact on the planet. Plus, as you participate, you'll earn points redeemable for rewards from your favorite brands. Join us in making sustainability a rewarding journey - one game at a time.
              </p>
              <LearnMoreButton link={'/my-sustainability'} text={'Learn More'} />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-y-2 md:gap-y-4 xl:gap-y-8'>
          <div className='w-full font-bold text-left text-2xl xl:text-3.5xl'>
            <p>Working with Dreamline</p>
          </div>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-8 text-left'>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={energyCosts} alt='piggy bank logo' />
              <p className='w-full xl:w-136 font-semibold text-lg'>Reduced Energy Costs</p>
              <p className='w-full xl:w-136 text-base'>Our assessments identify cost-effective measures to lower your energy bills.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={propertyValue} alt='home logo' />
              <p className='w-full xl:w-136 font-semibold text-lg'>Enhanced Property Value</p>
              <p className='w-full xl:w-136 text-base'>Energy-efficient upgrades can increase the market value of your property.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={comfortSafety} alt='shield logo' />
              <p className='w-full xl:w-136 font-semibold text-lg'>Improved Comfort and Safety</p>
              <p className='w-full xl:w-136 text-base'>Weatherization, electrification, and security enhancements contribute to a more comfortable and secure living environment.</p>
            </div>
            <div className='flex flex-col gap-y-2'>
              <img className='w-10 h-10' src={environmentalImpact} alt='seedling logo' />
              <p className='w-full xl:w-136 font-semibold text-lg'>Environmental Impact</p>
              <p className='w-full xl:w-136 text-base'>By reducing your property's energy consumption and carbon footprint, you contribute to a more sustainable future.</p>
            </div>
          </div>
        </div>

        <div className='w-full flex flex-col gap-y-[29px] xl:gap-y-8 items-center justify-center'>
          <p className='max-w-[1117px] text-lg xl:text-2xl'>
            Partner with Dreamline AI for your energy assessment and take the first step towards a more efficient, sustainable, and valuable property. Schedule your comprehensive energy assessment today.
          </p>
          <Link to='/pre-register'>
            <button
              className='inline-block h-10 rounded-[30px] px-14 py-2 text-white font-semibold text-lg bg-[#0070E0]'
            >Get started
            </button>
          </Link>
        </div>
      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};

