import React, { useState, useEffect } from 'react';
import './instituteinfo.css';
import CommonLayout  from '../../assets/components/js/CommonLayout';
import ArrowBack from '../../assets/components/js/ArrowBack';
import searchIcon from '../../assets/images/search-icon.png';
import { useNavigate } from 'react-router-dom';

export const InstituteInfo = () => {
    const [uniqueCompanyList, setUniqueCompanyList] = useState([]); //Company name list, retrieve from the backend API
    const [companyList, setCompanyList] = useState([]); //Company profile list, retrieve from the backend API based on the input company name
    const [filteredList, setFilteredList] = useState([]); //Filtered companyList based on the input zipcode
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [inputName, setInputName] = useState('');
    const [inputZip, setInputZip] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const serverURL = process.env.REACT_APP_SERVER_URL; 
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    
    // initialize the institute list
    useEffect(() => {
        const fetchUniqueCompanyList = async () => {
            const fetchURL = `${serverURL}/institute/getAllNames`;
            try {
                const response = await fetch(fetchURL, {
                    method: 'GET',
                    headers: {
                        'content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                if (response.ok) {
                    const uniqueCompanies = await response.json();
                    setUniqueCompanyList(uniqueCompanies);
                } else {
                    throw new Error('Failed to fetch company name\'s list');
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchUniqueCompanyList();
    }, [serverURL, token]);

    // render the institute list based on the zipcode dynamically
    useEffect(() => {
        if (inputZip) {
            const filteredList_zip = companyList.filter(company => company.zipcode.toString().startsWith(inputZip));
            setFilteredList(filteredList_zip);
        }else {
            setFilteredList(companyList);
            setSelectedCompany(null);
        }
    }, [inputZip, companyList]);
    
    // back to the initial list when inputName is empty
    useEffect(() => {
        if (!inputName) {
            setIsFiltered(false);
            setSelectedCompany(null);
        }
        setError('');
    }, [inputName]);

    const handleSearchClick = async (company_name) => {
        if (!company_name) return;
        const fetchURL = `${serverURL}/institute/getByName/${encodeURIComponent(company_name)}`;
        try {
            const response = await fetch(fetchURL, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const filteredEntries = await response.json();
                setCompanyList(filteredEntries);
                setFilteredList(filteredEntries);
                setSelectedCompany(null);
                setIsFiltered(true);
            } else {
                throw new Error('Failed to fetch companies');
            }
        } catch (error) {
            console.error(error);
        }
    };
  
    const handleInitialCompanyClick = (company) => {
        setInputName(company.name);
        handleSearchClick(company.name);
    };

    const handleFilteredCompanyClick = (company) => {
        setSelectedCompany(company);
        setInputZip(company.zipcode);
        setError('');
    };

    const handleNextClick = () => {
        if (!inputName) {
            setError('Please fill out the company name and select a profile.');
            return;
        }
        if (!/^\d{5}$/.test(inputZip))
        {
            setError('Please enter a valid zip code.');
            return;
        }
        if (!selectedCompany && filteredList.length > 0) {
            setError('Please select a profile');
            return;
        }
        if (isFiltered) {
            setShowModal(true);
            return;
        }
        setError('');
        handleJoinClick();        
    };

    const handleJoinClick = () => {
        try {
            // do API call
            alert("Backend API call needs to be added here!");
            navigate('/energy-assessment-home');
        } catch (error) {
            console.error("Error occurred during form submission:");
        }
    };    
    return (
        <CommonLayout>
            <br/>
            <div className="flex gap-10">
                <ArrowBack to="/signup-profileselection" />
                <h1 className="text-left text-2xl font-bold mb-4">Select your institute</h1>
            </div>
            <br/>
            <div>
                <div className="input-with-icon">
                    <input 
                        style={{ borderColor: '#43CCC4' }}
                        className="w-full bg-gray-50 border text-gray-900 sm:text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        type="text"
                        placeholder="Type company name here"
                        value={inputName}
                        onChange={(e) => setInputName(e.target.value)} 
                    />
                    <img 
                        src={searchIcon} 
                        alt="Search" 
                        onClick={() => handleSearchClick(inputName)} 
                    />
                </div>
                <br/>
                <input 
                    style={{ borderColor: '#43CCC4' }}
                    className="w-1/2 bg-gray-50 border text-gray-900 sm:text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    type="text" 
                    placeholder="Zip Code"
                    value={inputZip}
                    onChange={(e) => setInputZip(e.target.value)}
                />
                {error && <div style={{color: 'red'}}>{error}</div>}
                <br/>
                <ul className="divide-y divide-gray-200 border-b border-gray-200">
                    {isFiltered ? (
                        filteredList.length > 0 ? (
                            filteredList.map((company, index) => (
                                <li key={index} onClick={() => handleFilteredCompanyClick(company)} className="flex justify-between items-center py-4 hover:shadow-lg"> 
                                    <span className="font-semibold">{company.name}</span>
                                    <span>{company.zipcode}</span>
                                </li>
                            ))
                        ) : (
                            <li>
                                <p>No matching, click the Next button to claim a new profile.</p>
                            </li>
                        )
                    ) : 
                        uniqueCompanyList.map((company, index) => (
                            <li key={index} onClick={() => handleInitialCompanyClick(company)} className="flex items-center py-4 hover:shadow-lg">
                                <img src={`https://www.google.com/s2/favicons?domain=${company.url}`} alt={`${company.name} logo`} className="w-8 h-8 mr-4"/>
                                <div className="flex flex-col items-start">
                                    <span className="font-semibold">{company.name}</span>
                                    <small>{company.url}</small>
                                </div>
                            </li>
                        ))
                    }
                </ul>                
                <button className="mt-10 w-2/3 bg-[#43CCC4] hover:bg-green-400 text-white py-3 rounded-3xl font-bold transition duration-100" onClick={handleNextClick}>Next</button>
                {isFiltered && showModal && (
                    <div className="modal-overlay">
                        {filteredList.length === 0 ? (
                            <div className="modal">
                                <p className="text-left font-bold mb-4 text-2xl">Profile for <span className="text-[#43CCC4]">{inputName}</span> hasn't been created yet.</p>
                                <p className="text-left mb-10">Do you want to claim this institute and create a profile?</p>
                                <div className="flex justify-between gap-2">
                                    <button className="bg-white text-black border border-[#43CCC4] px-4 py-2 rounded-3xl font-bold w-full mr-2 " onClick={() => setShowModal(false)}>Back</button>
                                    <button className="bg-[#43CCC4] hover:bg-green-400 text-white px-4 py-2 rounded-3xl font-bold transition duration-100 w-full ml-2">Claim</button>
                                </div>
                            </div>
                        ) : (
                            <div className="modal">
                                <p className="text-left font-bold mb-4 text-2xl">Profile for <span className="text-[#43CCC4]">{selectedCompany.name}</span> already exists.</p>
                                <p className="text-left mb-10">Do you want to join this institute?</p>
                                <div className="flex justify-between gap-2">
                                    <button className="bg-white text-black border border-[#43CCC4] px-4 py-2 rounded-3xl font-bold w-full mr-2 " onClick={() => setShowModal(false)}>Cancel</button>
                                        <button className="bg-[#43CCC4] hover:bg-green-400 text-white px-4 py-2 rounded-3xl font-bold transition duration-100 w-full ml-2" onClick={() => handleJoinClick()}>Join</button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </CommonLayout>
    );
};

