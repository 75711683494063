import React from 'react'
import { Link } from 'react-router-dom';

import empoweringEveryone from '../../assets/images/empoweringEveryone.png';
import bridgingGap from '../../assets/images/bridgingGap.jpeg';
import newWay from '../../assets/images/newWay.jpeg';
import roadThroughForest from '../../assets/images/roadThroughForest.jpeg';
import propertyOwners from '../../assets/images/property_owners.svg';
import incentiveProviders from '../../assets/images/incentive_providers.svg';
import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';
import { LearnMoreButton } from '../../assets/components/js/navigation/LearnMoreButton';

export const Home = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-5 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-6 w-full xl:w-[1120px] '>

          <div className='relative flex justify-center overflow-hidden w-full h-90 xl:h-[666px] rounded-[30px] gap-x-8 gap-y-6'>
            <p className='absolute bottom-18 w-full text-[#F5F5F5] font-semibold text-2xl xl:text-3.5xl z-10'>Empowering Everyone to Access a Clean Future</p>
            <img
              className='absolute w-full h-90 object-cover rounded-[30px] xl:w-[1120px] xl:h-[666px]'
              src={empoweringEveryone} alt="buildings" loading='lazy'>
            </img>
          </div>

          <p className='max-w-[1119px] text-base lg:text-xl xl:text-2xl'>
            We streamline the access to resources for energy efficient and clean energy projects          </p>
          <Link to='/pre-register'>
            <button
              className='inline-block w-[390px] xl:w-full justify-center h-10 items-center rounded-[30px] px-6 xl:px-14 py-2 text-white text-center font-semibold text-lg bg-[#0070E0]'
            >Get Started
            </button>
          </Link>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-6'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120 '
            src={newWay}
            alt='new way'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left lg:order-last'>
            <p className='font-semibold text-2xl'>A New Way</p>
            <p className='text-lg'>We're changing the way capital is deployed for energy efficient and clean energy projects. Our platform speeds up incentive deployment, optimizes project management, and provides real-time impact reporting.</p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-6'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120 lg:order-last'
            src={bridgingGap}
            alt='bridge'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='font-semibold text-2xl'>Bridging the Gap</p>
            <p className='text-lg'>We made bridging that gap our mission, creating the ultimate hub to connect property owners and incentive program deployers, empowering people to make a difference. </p>
          </div>
        </div>



        <p className='hidden xl:block text-lg'>
          At Dreamline AI, our story is one of innovation, commitment, and a relentless pursuit of a sustainable future. We are proud to play a pivotal role in driving the transition to a cleaner, more efficient energy landscape.
        </p>

        <div className='relative w-full h-90 flex flex-col justify-center items-center'>
          <img
            className='w-full h-full rounded-[30px] object-cover'
            src={roadThroughForest}
            alt='Header'
            loading='lazy'
          />
          <p className='block absolute flex items-center text-left md:text-center top-8 w-full px-8 h-[296px] text-base xs:text-lg items-center text-white xl:hidden'>
            At Dreamline AI, our story is one of innovation, commitment, and a relentless pursuit of a sustainable future. We are proud to play a pivotal role in driving the transition to a cleaner, more efficient energy landscape.
          </p>
        </div>

        <p className='max-w-[1120px] text-lg lg:text-lg xl:text-lg'>
          Join us as we continue to break down barriers, accelerate progress, and create lasting positive change for our planet and future generations.
        </p>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6'>
          <div className='h-full flex flex-col items-start justify-between gap-y-8'>
            <div className='flex flex-col gap-y-4'>
              <div className='w-full xl:w-[543px] h-54 py-8 xl:h-90 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-full object-contain'
                  src={propertyOwners}
                  alt='Sweet Home'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='w-full font-semibold text-2xl'>Property Owners </p>
                <p className='w-full xl:w-136 text-lg'>Dreamline AI is your digital tool kit all the way from initial property assessment, securing incentives, and managing your upgrade projects.</p>
              </div>
            </div>
            <LearnMoreButton link='/property-owners' text='Learn More' />
          </div>
          <div className='flex flex-col items-start justify-between gap-y-8'>
            <div className='flex flex-col gap-y-4'>
              <div className='w-full xl:w-[543px] h-54 py-8 xl:h-90 bg-white flex justify-center items-center rounded-[30px]'>
                <img
                  className='w-full h-full object-contain'
                  src={incentiveProviders}
                  alt='bitcoin'
                  loading='lazy'
                />
              </div>
              <div className='flex flex-col gap-y-2 text-left'>
                <p className='w-full font-semibold text-2xl'>Incentive Providers</p>
                <p className='w-full xl:w-136 text-lg'>Dreamline is your comprehensive platform for efficiently deploying incentives, analyzing impact, and leveraging data-driven insights to maximize results.</p>
              </div>
            </div>
            <LearnMoreButton link='/incentives' text='Learn More' />
          </div>
        </div>
      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};

