import {React, useRef} from 'react';
import groupPic from '../../assets/images/group-pic.png';
import logo from '../../assets/images/logo.png';
import InfoPopUp from '../../assets/components/js/InfoPopUp';

export const UserIncentive = () => {


    //variables for storing the form data
    const incentiveDataRef = useRef({
        propertyType: null,
        property: "single",
        houseHoldSize: 3,
        houseHoldIncome: 50000
    })
    
    //method used for sending form data to server.
    //Forms' attributes of 'method' and 'action' do not seem to work properly,
    //aren't sending the body parameter for a request correctly
    const submitForm = async () => {

        //URL for the server. Change when production time comes.
        const serverURL = process.env.REACT_APP_SERVER_URL;
        const fetchURL = `${serverURL}/incentive`;

        //fetch request. sends form data, gets answer back in form of status code.
        await fetch(fetchURL, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json' 
            },
            body: JSON.stringify(incentiveDataRef.current) 
        }).then((response) => {
            console.log(response.status)
        })
    }
 

    return (
        <>
            <div
                className="flex min-h-screen bg-no-repeat"
                style={{
                    backgroundImage: `url(${groupPic})`,
                    minHeight: '100vh',
                    width: '80%',
                    margin: '20px auto',
                }}
            >
                <div className="w-5/6  p-8  my-14 mx-auto rounded-lg">
                    <div className="w-full md:max-w-lg md:min-w-0  bg-white bg-opacity-90 p-10 rounded-l-lg mt-14 ml-auto">
                        <div>
                            <img className="mr-4" src={logo} alt="" />

                            <br />
                            <div>
                                <h1 className="text-left text-2xl font-bold mb-4">Unlock Your Eligible Incentives!</h1>
                            </div>
                            <div>
                                <p style={{ color: '#2B74F1' }} className="text-left mb-4 text-base font-bold">Why are we asking this? </p>

                                <form className="space-y-6">
                                    <div>
                                        <p className="font-semibold mb-4 text-left">Tell us about your owned property type</p>
                                        <div className="flex items-center mb-4">
                                            <input id="residential" type="radio" name="propertyType" value="Residential" className="w-4 h-4 p4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" onChange={(e) => incentiveDataRef.current.propertyType = e.target.value}/>
                                            <label for="residential" className="ml-2 text-sm font-medium text-gray-900">Residential Home</label>
                                        </div>
                                        <div className="flex items-center mb-8">
                                            <input id="commercial" type="radio" name="propertyType" value="Commercial" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600" onChange={(e) => incentiveDataRef.current.propertyType = e.target.value}/>
                                            <label for="commercial" className="ml-2 text-sm font-medium text-gray-900">Commercial Home</label>
                                        </div>
                                    </div>
                                    <div className="flex ">
                                        <p className="text-left font-semibold mb-2">What type of residential property do you own?</p>

                                        <InfoPopUp />
                                    </div>
                                    <div className="flex items-center mb-2">

                                        <select style={{ borderColor: '#43CCC4' }} id="residentialType" name="residentialType" className="w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={(e) => incentiveDataRef.current.property = e.target.value}>
                                            <option value="single">Condominium</option>
                                            <option value="multi">Townhouse</option>
                                            <option value="apartment">Duplex</option>
                                            <option value="apartment">Single Family</option>
                                            <option value="apartment">Multi-Family</option>
                                            <option value="apartment">Mobile Home</option>
                                            <option value="apartment">Apartment</option>
                                            <option value="apartment">Mixed Use</option>
                                            <option value="apartment">Vacant Land</option>

                                        </select>
                                    </div>

                                    <div className="flex items-center mb-2">
                                        <p className="text-left font-semibold mb-2">Household Size</p>
                                        <InfoPopUp />
                                    </div>
                                    <div>
                                        <input style={{ borderColor: '#43CCC4' }} type="number" id="householdSize" name="householdSize" placeholder="3" className="w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={(e) => incentiveDataRef.current.houseHoldSize = e.target.value}/>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        <p className="text-left font-semibold mb-2">Household Income</p>
                                        <InfoPopUp />
                                    </div>
                                    <div className="relative flex items-center">
                                        <input style={{ borderColor: '#43CCC4' }} type="number" id="householdIncome" name="householdIncome" placeholder="$50,000" className="w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-3xl focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={(e) => incentiveDataRef.current.houseHoldIncome = e.target.value}/>
                                    </div>

                                    <div>
                                        <button className="mt-4 w-full bg-[#43CCC4] hover:bg-green-400 text-white py-3 rounded-2xl transition duration-100" type="submit" onClick={(e) => {
                                            e.preventDefault();
                                            submitForm()
                                            }}>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};
