import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./profileselection.css";
import iconGovernment from "../../../assets/images/government_official.png";
import iconFinancial from "../../../assets/images/financial_institute.png";
import iconBusiness from "../../../assets/images/business_owner.png";
import iconContractor from "../../../assets/images/contractor.png";
import iconProperty from "../../../assets/images/property_owner.png";
import ProfileOption from "./ProfileOption";
import SignUpLayout from "../../../assets/components/js/SignUpLayout";

export const SignUpProfileSelection = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const options = [
    {
      icon: iconGovernment,
      label: "Government Official",
      value: "Government Official",
    },
    {
      icon: iconFinancial,
      label: "Financial Institute",
      value: "Financial Institute",
    },
    {
      icon: iconContractor,
      label: "Contractor",
      value: "Contractor",
    },
    {
      icon: iconBusiness,
      label: "Business Owner",
      value: "Business Owner",
    },
    {
      icon: iconProperty,
      label: "Property Owner",
      value: "Property Owner",
    },
  ];

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    setHasError(false);
  };

  const mapOptionToUserTypeId = (selectedOption) => {
    const userTypeMapping = {
      "Government Official": 1,
      "Financial Institute": 2,
      Contractor: 3,
      "Business Owner": 4,
      "Property Owner": 5,
    };

    return userTypeMapping[selectedOption];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedOption) {
      const userTypeId = mapOptionToUserTypeId(selectedOption);
      //URL for the server. Change when production time comes.
      const serverURL = process.env.REACT_APP_SERVER_URL;
      const fetchURL = `${serverURL}/users/update-userType/`;
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(fetchURL, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userTypeId }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          //Redirect to the next page according the usertype
          if (userTypeId === 5) {
            navigate("/homeownerpersonal");
          } else if (userTypeId === 2) {
            navigate("/instituteinfo");
          } else if (userTypeId === 3) {
            navigate("/contractorinfo");
          } else {
            console.error("Not defined usertypeid dashboard");
          }
        } else {
          throw new Error("Failed to update user type");
        }
      } catch (error) {
        console.error(error);
        setHasError(true);
      }

      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <>
      <SignUpLayout>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="container-div">
              {options.map((option, index) => (
                <ProfileOption
                  key={index}
                  icon={option.icon}
                  label={option.label}
                  value={option.value}
                  selectedValue={selectedOption}
                  onChange={handleChange}
                />
              ))}
            </div>
            {hasError && (
              <div className="error-message">
                Please select a profile to proceed.
              </div>
            )}
            <div className="py-2">
              <button
                className="mt-10 w-2/3 bg-[#43CCC4] hover:bg-green-400 text-white py-3 rounded-3xl font-bold transition duration-100"
                type="submit"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </SignUpLayout>
    </>
  );
};
