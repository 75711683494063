import DreamLineLogo from "../../assets/images/dreamlineai-logo-simple.png";
import { useNavigate } from "react-router-dom";
import instagramIcon from "../../assets/images/SocialIcons/Instagram.png";
import linkedInIcon from "../../assets/images/SocialIcons/LinkedIn.png";
import facebookIcon from "../../assets/images/SocialIcons/Facebook.png";
import twitterXIcon from "../../assets/images/SocialIcons/TwitterX.png";
import MainHeader from "../../assets/components/js/MainHeader";
import MainFooter from "../../assets/components/js/MainFooter";
import "../../assets/style/Careers.css";

export const ProjectManagement = () => {
  const navigate = useNavigate();

  return (
    <div className="revised-home flex flex-col h-screen">
      <MainHeader />

      <div className="careers-overview-container">
        <section className="careers-introduction">
          <p>
            Dreamline AI offers comprehensive project management services
            designed to ensure the successful implementation of your energy
            efficiency upgrades. Our approach encompasses three key areas:
            Project Implementation, Supply Chain Management, and Customer
            Service. From the initial audit to the final inspection, our local
            representatives are there to support you every step of the way.
          </p>
        </section>
        <section className="careers-section">
          <h2>
            <strong>Project Implementation</strong>
          </h2>
          <h2>Initial Audit and Scope Identification</h2>
          <ul>
            <li>
              <strong>Local Representatives:</strong> Our dedicated local
              representatives conduct thorough energy audits to assess your
              property’s specific needs. This hands-on approach ensures that all
              potential improvements are identified and prioritized.
            </li>
            <li>
              <strong>Customized Plans:</strong> Based on the audit, we develop
              a detailed scope of work tailored to your property. This plan
              outlines the specific energy efficiency measures to be
              implemented, ensuring that your project is both effective and
              cost-efficient.
            </li>
          </ul>
          <h2>Execution and Oversight</h2>
          <ul>
            <li>
              Expert Coordination Our team coordinates with trusted contractors
              and vendors to ensure that each aspect of the project is executed
              to the highest standards. We manage all stages of the
              implementation process, from planning to execution.
            </li>
            <li>
              <strong>Quality Assurance:</strong> Continuous oversight and
              quality checks are conducted throughout the project to ensure that
              all work meets our stringent quality standards and complies with
              relevant regulations.
            </li>
          </ul>
          <h2>Final Inspection</h2>
          <ul>
            <li>
              Completion Review Once the project is completed, our local
              representative conducts a final inspection to verify that all work
              has been carried out correctly and that the agreed-upon
              improvements have been made.
            </li>
            <li>
              <strong>Performance Verification:</strong> We ensure that the
              implemented measures perform as expected and deliver the promised
              energy savings and efficiency improvements.
            </li>
          </ul>
        </section>
        <section className="careers-section">
          <h2>
            <strong>Supply Chain Management</strong>
          </h2>
          <h2>Efficient Sourcing</h2>
          <ul>
            <li>
              <strong>Reliable Partners:</strong> We have established
              relationships with a network of reliable suppliers and
              manufacturers to source the best materials and equipment for your
              project.
            </li>
            <li>
              <strong>Quality Assurance:</strong> Our supply chain management
              strategies focus on obtaining high-quality products at competitive
              prices, ensuring that your project remains within budget.
            </li>
          </ul>
          <h2>Timely Delivery</h2>
          <ul>
            <li>
              <strong>Logistical Coordination:</strong> We handle all aspects of
              logistics, coordinating the delivery of materials and equipment to
              align with the project timeline. This minimizes delays and keeps
              your project on schedule.
            </li>
            <li>
              <strong>Inventory Management:</strong> Our team manages inventory
              to ensure that all necessary components are available when needed,
              preventing any disruptions to the project flow.
            </li>
          </ul>
        </section>
        <section className="careers-section">
          <h2>
            <strong>Customer Service</strong>
          </h2>
          <h2>Dedicated Support</h2>
          <ul>
            <li>
              <strong>Personalized Assistance:</strong> Our customer service
              team provides personalized support throughout the project. From
              answering initial inquiries to addressing any concerns during and
              after implementation, we are here to help.
            </li>
            <li>
              <strong>Ongoing Communication:</strong> We maintain open lines of
              communication, keeping you informed about the progress of your
              project and any updates or changes that may arise.
            </li>
          </ul>
          <h2>Feedback and Improvement</h2>
          <ul>
            <li>
              <strong>Customer Feedback:</strong> We actively seek and value
              feedback from property owners to continually improve our services.
              Your insights help us enhance our processes and better meet your
              needs.
            </li>
            <li>
              <strong>Continuous Improvement:</strong> Our commitment to
              excellence means that we are always looking for ways to improve
              our project management practices, ensuring that we deliver the
              best possible outcomes for our clients.
            </li>
          </ul>
        </section>
        <section className="careers-section">
          <h2>
            <strong>Benefits of Dreamline AI’s Project Management</strong>{" "}
          </h2>
          <p>
            <strong>Streamlined Implementation:</strong> Our comprehensive
            project management services ensure that your energy efficiency
            upgrades are implemented smoothly and efficiently, with minimal
            disruption to your property.
          </p>

          <p>
            <strong>Expert Oversight:</strong> With dedicated local
            representatives and expert coordination, you can trust that your
            project is in capable hands from start to finish.
          </p>
          <p>
            <strong>Enhanced Communication:</strong> Our commitment to clear and
            ongoing communication means that you are always informed and
            supported throughout the project.
          </p>
          <p>
            <strong>Quality Assurance:</strong> Rigorous quality checks and
            final inspections ensure that all work meets the highest standards
            and delivers the expected benefits.
          </p>
        </section>
        <section className="careers-section">
          <p>
            Dreamline AI’s project management services provide property owners
            with a seamless and reliable pathway to energy efficiency. Contact
            us today to learn how we can help you manage your energy efficiency
            upgrades with precision and care.
          </p>
        </section>
      </div>
      <MainFooter />
    </div>
  );
};
