import { useState, useEffect, useRef} from "react"
import chevronLeft from "../../images/chevron-left.png"
import chevronRight from "../../images/chevron-right.png"
import { CalenderDay } from "./CalenderDay"
import "../css/Assessment.css"

export const Calender = (props) => {

    const [month, setMonth] = useState()
    const [year, setYear] = useState()
    const [monthMatrix, setMonthMatrix] = useState([[],[],[],[],[],[],[]])
    const [formDay, setFormDay] = useState()
    const currentMonthRef = useRef()
    const currentYearRef = useRef()
    const selectedDayRef = useRef()

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const shortDaysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const makeDaysMatrix = (year, month) => {
        const firstDay = new Date(year, month, 1)

        const lastDay = new Date(year, month + 1, 0).getDate()
        const lastDayPreviousMonth = new Date(year, month, 0).getDate()
        
        setMonthMatrix(occupyMatrix(firstDay.getDay(), lastDay, lastDayPreviousMonth))
    }

    const occupyMatrix = (firstDay, lastDay, lastDayPreviousMonth) => {

        let currentDateObject = new Date()
        currentDateObject = currentDateObject.setDate(currentDateObject.getDate() - 1)
        let currentDate = 1;
        let row = firstDay;
        let col = 0;
        let matrix = [[], [], [], [], [], [], []]
        for (let i = firstDay - 1; i > -1; i--) {
            matrix[i][0] = {
                dateNum: lastDayPreviousMonth,
                isAvailable: false
            };
            lastDayPreviousMonth--;
        }
        while (currentDate <= lastDay) {
            let thisDay = new Date(currentYearRef.current, currentMonthRef.current, currentDate)
            matrix[row][col] = {
                dateNum: currentDate,
                date: thisDay,
                isAvailable: thisDay >= currentDateObject ? true : false
            };
            if (row == 6) {
                row = 0;
                col++;
                currentDate++
                continue;
            }
            row++;
            currentDate++;
        }

        currentDate = 1;
        for (let i = col; i < 6; i++) {
            for (let j = row; j < 7; j++) {
                matrix[j][i] = {
                    dateNum: currentDate,
                    isAvailable: false
                };
                currentDate++;
            }
            row = 0;
        }

    

        return matrix
    }

    useEffect(() => {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth()

        setMonth(months[currentMonth])
        setYear(currentDate.getFullYear())

        currentMonthRef.current = currentMonth;
        currentYearRef.current = currentDate.getFullYear()

        makeDaysMatrix(currentDate.getFullYear(), currentMonth)
    }, [])

    const changeMonth = (shouldForward) => {
        if (shouldForward) {
            const currentDate = currentMonthRef.current == 11 ? new Date(currentYearRef.current + 1, 0) : new Date(currentYearRef.current, currentMonthRef.current + 1);
            const currentMonth = currentDate.getMonth()

            setMonth(months[currentMonth])
            setYear(currentDate.getFullYear())

            currentMonthRef.current = currentMonth;
            currentYearRef.current = currentDate.getFullYear()
        }
        else {
            const currentDate = currentMonthRef.current == 0 ? new Date(currentYearRef.current - 1, 11) : new Date(currentYearRef.current, currentMonthRef.current - 1);
            const currentMonth = currentDate.getMonth()

            setMonth(months[currentMonth])
            setYear(currentDate.getFullYear())

            currentMonthRef.current = currentMonth;
            currentYearRef.current = currentDate.getFullYear()
        }
        setFormDay(null)
    }

    useEffect(() => {
        makeDaysMatrix(currentYearRef.current, currentMonthRef.current)
    }, [month])

    useEffect(() => {
        props.setSelectedDate(formDay?.date)
    }, [formDay])


    return <div className="calender w-[300px] h-[350px] flex flex-col items-center rounded-[20px] shadow-[" style={{
        backgroundColor: "white",
        boxShadow: "0px 5px 5px gray",
    }}>
        <div className="calender-top-part flex items-center justify-between p-[10px] w-[80%] border-b border-black-500" style={{
            borderBottom: "1px solid black"
        }}>
            <button className="transition-all duration-100 transform hover:scale-125" onClick={() => {changeMonth(false)}}> <img src={chevronLeft} alt="previous month"/></button>
            <h1> {month} {year}</h1>
            <button className="transition-all duration-100 transform hover:scale-125" onClick={() => {changeMonth(true)}}> <img src={chevronRight} alt="next month"/></button>
        </div>
        <div className="flex justify-between w-[80%] h-[100%]">
            {shortDaysOfWeek.map((item, index) => {
                return <div key={index} className="day-column flex flex-col justify-between pb-[10px]">
                    <p className="text-[10px]"> {item.toUpperCase()}</p>

                    <CalenderDay dayObject={monthMatrix[index][0]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                    <CalenderDay dayObject={monthMatrix[index][1]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                    <CalenderDay dayObject={monthMatrix[index][2]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                    <CalenderDay dayObject={monthMatrix[index][3]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                    <CalenderDay dayObject={monthMatrix[index][4]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                    <CalenderDay dayObject={monthMatrix[index][5]} changeDay={setFormDay} selectedDay={selectedDayRef}/>
                </div>
            })}
        </div>
    </div>
}
