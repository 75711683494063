import React from 'react'
import { Link } from 'react-router-dom';

import { DreamLineLogo } from './DreamLineLogo';
import facebook from '../../../images/SocialIcons/Facebook.svg';
import instagram from '../../../images/SocialIcons/Instagram.svg';
import twitterX from '../../../images/SocialIcons/TwitterX.svg';
import linkedin from '../../../images/SocialIcons/LinkedIn.svg';

// To use footer in any component copy this snippet with div element to occupy full screen
// <div className='w-full flex justify-center bg-white'>
//   <Footer />
// </div>

const navigationLinks = [
  {
    title: 'Property Owners',
    path: '/property-owners',
    // subLinks: [
    //   { name: 'Energy Assessments', path: '/energy-assessments' },
    //   { name: 'Incentives', path: '/incentives' },
    //   { name: 'Project Management', path: '/project-management' }
    // ]
  },
  {
    title: 'Incentive Providers',
    path: '/incentive-providers',
    // subLinks: [
    //   { name: 'Incentive Deployment', path: '/incentive-deployment' },
    //   { name: 'Project Management', path: '/project-management' },
    //   { name: 'Impact Reporting', path: '/impact-reporting' }
    // ]
  },
  {
    title: 'About',
    // path: '/our-story',
    subLinks: [
      { name: 'Our Story', path: '/our-story' },
      { name: 'My Sustainability App', path: '/my-sustainability-app' },
      { name: 'Careers', path: '/careers' }
    ]
  }
];

const socialMediaLinks = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com',
    icon: facebook,
    alt: 'facebook logo'
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/dreamline_ai?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
    icon: instagram,
    alt: 'instagram logo'
  },
  {
    name: 'TwitterX',
    link: 'https://twitter.com/cdreamsorg',
    icon: twitterX,
    alt: 'twitterX logo'
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/dreamlineai/',
    icon: linkedin,
    alt: 'linkedin logo'
  }
];

export const Footer = () => {
  return (
    <div className='w-full xl:w-[1440px] h-[390px] relative px-8 xs:px-[60px] xl:px-40 py-6 bg-white'>
      <div className='w-full xl:w-[1120px] relative bg-white'>

        <div className='absolute top-0 xs:top-[50px] lg:top-[196px]'>
          <DreamLineLogo />
        </div>

        <div className='absolute top-[180px] xs:top-8 lg:top-44 xs:right-0 lg:left-[calc(75%+32px)] flex flex-col gap-y-2'>
          <p className='w-40 h-6 font-semibold text-base xs:text-lg text-left'>Follow us:</p>
          <div className='flex gap-x-3'>
            {socialMediaLinks.map((social, index) => (
              <div key={index} className='w-8 h-8 flex items-center justify-center rounded-full bg-[#E9F4FF]'>
                <a href={social.link} target='_blank' rel='noopener noreferrer'>
                  <img className='w-4 h-4' src={social.icon} alt={social.alt} />
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className='absolute w-full xl:w-[1120px] top-15 lg:top-12 grid grid-rows-1 lg:grid-cols-4 gap-x-8 gap-y-1 lg:gap-y-0 text-left'>
          {navigationLinks.map((link, index) => (
            <div key={index} className='flex flex-col lg:h-[120px] gap-y-2'>
              {link.path ? (
                <Link to={link.path}>
                  <p className='h-6 font-bold text-base xs:text-lg'>{link.title}</p>
                </Link>
              ) : (
                <p className='h-6 font-bold text-base xs:text-lg'>{link.title}</p>
              )}
              {link.subLinks && (
                <div className='hidden lg:flex flex-col text-lg'>
                  {link.subLinks.map((subLink, subIndex) => (
                    <a key={subIndex} href={subLink.path}>{subLink.name}</a>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className='flex xs:flex-row lg:flex-col gap-y-0 xs:gap-y-2'>
            <p className='xs:h-6 text-left font-bold text-base xs:text-lg mr-2'>Contact Us:</p>
            <p className='font-bold hidden xs:block lg:hidden'>:</p>
            <a className='text-base xs:text-lg pl-0 xs:pl-1 lg:pl-0' href='mailto:rose@dreamlineai.org'>
              Rose@dreamlineai.org
            </a>
          </div>

        </div>

        <p className='absolute w-full xl:w-[1120px] h-[62px] top-[267px] text-center text-xs'>
          Copyright © 2022 Dreamline AI. All rights reserved. Unauthorized use or reproduction of content is strictly prohibited. 
        </p>

      </div>
    </div>
  )
}
