import React, { useEffect } from "react";
import "../../assets/components/css/Assessment.css"
import { AssessmentTab } from "../../assets/components/js/AsessmentTab";
import { TopBar } from "../../assets/components/js/TopBar";
import scheduleAssessmentIcon from '../../assets/images/energy-assessment-image.png'
import { Footer } from "../../assets/components/js/Footer";
import { useState } from "react"
import { ScheduleAssessmentPopUp } from "../../assets/components/js/ScheduleAssessmentPopUp"
import { ScheduleCompletion } from "../../assets/components/js/ScheduleCompletion"



export const Assessment = () => {

    const [scheduleInfo, setScheduleInfo] = useState()
    
    
    return <div className="relative z-0 bg-gray-100 w-[100vw] flex flex-col overflow-x-hidden">
        
        <div className="overlay-gray absolute z-[100] w-[100%] pb-[1px] hidden h-[100%] ">
            <div className="inner relative flex flex-col">
                <ScheduleAssessmentPopUp setScheduleInfo={setScheduleInfo}/>
                <ScheduleCompletion scheduleInfo={scheduleInfo}/>
            </div>
        </div>
          
        <TopBar />
        <AssessmentTab/>
        
        <div className="mb-[50vh] max-w-[60%] min-w-[30%px] min-h-[300px] mt-[200px] xl:ml-[17vw] ml-[250px] rounded-[40px] flex items-center justify-between pl-[3vw] pr-[3vw] pt-[30px] pb-[30px] lg:flex-row flex-col" style={{
            backgroundColor: 'white',
            boxShadow: '0px 15px 15px gray'
        }}>
            <div className="flex flex-col gap-[20px] items-center lg:items-baseline items-center">
                <h1 className="text-[40px] lg:text-left text-center font-bold"> Schedule your Energy Assessment </h1>
                <p className="max-w-[80%] lg:text-left text-center text-[20px]"> Discover home upgrades tailored for you! Complete your energy assessment for personalized incentives and a smarter home.</p>
                <button className="bg-[#43CCC4] w-[190px] h-[60px] text-[25px] font-bold text-white rounded-[30px] transition-all duration-300 transform hover:translate-y-[-5px] items-center justify-center" onClick={() => {
                    try {
                        document.querySelector(".schedule-assessment-page").classList.remove("hidden")
                        document.querySelector(".overlay-gray").classList.remove("hidden")
                    }
                    catch (e) {
                        alert("Error: Assessment creator overlay not implemeneted")
                    }
                }}> Continue </button>
            </div>
            <img src={scheduleAssessmentIcon} alt="Schedule your Energy Assessment image"/>
            
        </div>
        <Footer />
        

    </div>
}
