import { useNavigate } from "react-router-dom"
import MainFooter from "../../assets/components/js/MainFooter"
import MainHeader from "../../assets/components/js/MainHeader"

export const IncentiveDeployment = () => {

    const navigate = useNavigate()

    return <div className="revised-home flex flex-col h-screen">
        <MainHeader />

        <div className="careers-overview-container">
            <section className="careers-introduction">
                <p>Dreamline AI is revolutionizing the way incentives are deployed for energy efficiency and clean energy projects. Our platform is designed to streamline and accelerate the incentive deployment process, making it easier for government agencies, banks, non-profits, businesses, and real estate developers to administer their programs effectively and efficiently.</p>
            </section>
            <section className="careers-section">
                <h2 className="font-bold">Streamlined Application Process</h2>
                <p style={{ color: "#ff6f61" }}>Effortless Integration</p>
                <ul>
                    <li><strong>Seamless Integration:</strong> Our platform integrates seamlessly with existing systems, reducing the need for manual processes and minimizing administrative burdens. This allows incentive providers to focus on their core missions while we handle the complexities of application processing.</li>
                    <li><strong>User-Friendly Interface:</strong> Our intuitive interface makes it easy for applicants to find, apply for, and track incentives, ensuring a smooth and hassle-free experience for all users.</li>
                </ul>
                <p style={{ color: "#ff6f61" }}>Comprehensive Database</p>
                <ul>
                    <li><strong>Extensive Catalog:</strong> We maintain a comprehensive database of over 10,000 incentives, ensuring that applicants can easily identify and access the most relevant programs. This extensive catalog simplifies the process for both applicants and providers, enhancing the overall efficiency of the incentive deployment process.</li>
                    <li><strong>Real-Time Updates:</strong> Our database is continuously updated with the latest information, ensuring that incentive providers can offer the most current and relevant programs to their target audiences.</li>
                </ul>
            </section>
            <section className="careers-section">
                <h2 className="font-bold">Advanced Eligibility and Application Management</h2>
                <p style={{ color: "#ff6f61" }}>Machine Learning Algorithms</p>
                <ul>
                    <li><strong>Eligibility Determination:</strong> Our advanced machine learning algorithms quickly and accurately determine eligibility for various incentives, reducing the time and effort required for both applicants and providers. This ensures that eligible applicants are identified and approved promptly.</li>
                    <li><strong>Automated Processing:</strong> Automation of the application process reduces errors and ensures consistent and fair evaluation, leading to faster and more reliable outcomes.</li>
                </ul>
                <p style={{ color: "#ff6f61" }}>Customizable Criteria</p>
                <ul>
                    <li><strong>Tailored Solutions:</strong> We work closely with incentive providers to customize eligibility criteria and application processes to meet their specific needs. This flexibility ensures that programs are designed to achieve their intended goals effectively.</li>
                    <li><strong>Dynamic Adjustments:</strong> Our platform allows for dynamic adjustments to criteria and processes, enabling providers to respond quickly to changing circumstances and emerging needs.</li>
                </ul>
            </section>
            <section className="careers-section">
                <h2 className="font-bold">Efficient Deployment and Tracking</h2>
                <p style={{ color: "#ff6f61" }}>Rapid Disbursement</p>
                <ul>
                    <li><strong>Accelerated Deployment:</strong> Our platform ensures rapid disbursement of incentives, reducing delays and improving satisfaction among applicants. This accelerated deployment process helps providers achieve their program objectives more quickly.</li>
                    <li><strong>Secure Transactions:</strong> We use secure, state-of-the-art technology to ensure that all transactions are safe and reliable, giving both providers and applicants peace of mind.</li>
                </ul>
                <p style={{ color: "#ff6f61" }}>Impact Reporting</p>
                <ul>
                    <li><strong>Detailed Metrics:</strong> Our platform offers detailed reporting on the impact of deployed incentives, providing valuable insights into program performance. These metrics help providers understand the effectiveness of their programs and make data-driven decisions.</li>
                    <li><strong>Transparency and Accountability:</strong> Comprehensive reporting ensures transparency and accountability, enabling providers to demonstrate the value and impact of their programs to stakeholders and funders.</li>
                </ul>
            </section>
            <section className="careers-section">
                <h2 className="font-bold">Enhanced Collaboration and Support</h2>
                <p style={{ color: "#ff6f61" }}>Partnership Approach</p>
                <ul>
                    <li><strong>Collaborative Design:</strong> We partner directly with incentive providers to design and implement programs that are effective and user-friendly. This collaborative approach ensures that programs are tailored to meet the unique needs of each provider and their target audiences.</li>
                    <li><strong>Ongoing Support:</strong> Our dedicated support team is available to assist providers at every step of the process, from program design to deployment and beyond. This ensures that providers have the resources and assistance they need to succeed.</li>
                </ul>
                <p style={{ color: "#ff6f61" }}>Community Engagement</p>
                <ul>
                    <li><strong>Local Outreach:</strong> We help providers engage with local communities to raise awareness and encourage participation in incentive programs. This local outreach ensures that programs reach the intended audiences and achieve maximum impact.</li>
                    <li><strong>Feedback Integration:</strong> We actively seek feedback from both providers and applicants to continuously improve our platform and processes. This ongoing feedback loop ensures that we remain responsive to the needs of all stakeholders.</li>
                </ul>
            </section>
            <section className="careers-section">
                <h2 className="font-bold">Benefits for Incentive Providers</h2>
                <ul>
                    <li><strong>Efficiency:</strong> Streamlined processes and automated systems reduce administrative burdens and improve efficiency.</li>
                    <li><strong>Speed:</strong> Rapid deployment and disbursement of incentives ensure timely support for energy efficiency and clean energy projects.</li>
                    <li><strong>Transparency:</strong> Detailed reporting and metrics provide transparency and accountability, demonstrating the impact and value of programs.</li>
                    <li><strong>Collaboration:</strong> Our partnership approach and ongoing support ensure that providers have the resources and assistance they need to succeed.</li>
                    <li><strong>Community Impact:</strong> Enhanced community engagement and outreach ensure that programs reach and benefit the intended audiences.</li>
                </ul>
            </section>
            <section className="careers-section">
                <p>Dreamline AI's platform is designed to make the deployment of incentives for energy efficiency and clean energy projects as seamless and effective as possible. By leveraging our advanced technology and collaborative approach, incentive providers can achieve their program goals with greater speed and efficiency. Contact us today to learn how we can help you deploy your incentives more effectively and make a lasting impact on your community.</p>
            </section>
        </div>

        <MainFooter />
    </div >
}
