import searchIcon from '../../images/search.png'
import defaultUserIcon from '../../images/default-user-icon.png'
import contactSupportIcon from '../../images/contact_support.png'
import bellIcon from '../../images/bell-icon.png'



export const TopBar = () => {




    return <div className="w-[100%] min-h-[150px] z-0 absolute flex items-center justify-between" style={{
        backgroundColor: 'white'
    }}>
        <div className="search-bar min-w-[400px] w-[600px] bg-gray-100 h-[80px] xl:ml-[17vw] ml-[250px] rounded-[50px] flex items-center">
            <img src={searchIcon} alt="Search Bar Icon" className="ml-[50px]" />
            <input type="text" placeholder='Search...' className="h-[80%] w-[70%] ml-[30px] focus:outline-none" style={{
                fontSize: "30px",
                backgroundColor: 'rgba(255, 255, 255, 0)'
            }}/>
        </div>
        <div className="mr-[50px] flex items-center justify-center gap-[50px] nav-bar-icons">
            <img src={contactSupportIcon} alt="Contact Support Icon" className="w-[40px]" />
            <img src={bellIcon} alt="Notifications Icon" className="w-[40px]"/>
            <img src={defaultUserIcon} alt="Profile Icon" className="w-[50px]"/>
            
            
        </div>
    </div>
}
