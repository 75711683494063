import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import downArrow from '../../../images/chevron-down-solid.svg';
import menuButton from '../../../images/menu_button.svg';

import { DreamLineLogo } from './DreamLineLogo';

// To use header in any component copy this snippet with div element to occupy full screen
// <div className='w-full flex justify-center bg-white'>
//   <Header />
// </div>

export const Header = () => {

  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuOpen = (menu) => {
    setIsMenuOpen(menu);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'font-bold' : '';
  };

  return (
    <div className='w-full flex justify-center px-8 xs:px-[60px] py-6 gap-y-2 bg-white xl:w-[1440px] xl:px-40'>
      <div className='w-full xl:w-[1120px] flex items-center justify-between'>

        <DreamLineLogo />

        <div className='hidden lg:flex lg:gap-x-20'>
          <div className='flex gap-x-1'>
            <Link to={'/property-owners'}>
              <p className={`w-[148px] h-6 text-lg whitespace-nowrap ${getLinkClass('/property-owners')}`}>Property Owners</p>
            </Link>
          </div>

          <div className='flex gap-x-1'>
            <Link to={'/incentive-providers'}>
              <p className={`w-[148px] h-6 text-lg whitespace-nowrap ${getLinkClass('/incentive-providers')}`}>Incentive Providers</p>
            </Link>
          </div>

          <div className='flex flex-col relative'
            onMouseEnter={() => handleMenuOpen('about')}
            onMouseLeave={handleMenuClose}
          >
            <div className='flex items-center gap-x-1'>
              <p className={`text-lg whitespace-nowrap ${getLinkClass('/our-story')}${getLinkClass('/my-sustainability-app')}${getLinkClass('/careers')}`}>About</p>
              <img className='w-4 h-4 object-fit' src={downArrow} alt='down arrow logo' />
            </div>

            {isMenuOpen === 'about' && (
              <div className='absolute top-full pt-9 z-10'>
                <ul className='text-start bg-white shadow-md rounded-[10px] gap-y-[14px] pl-[10px] pt-5 pr-10 pb-5' onClick={handleMenuClose}>
                  <li className='px-4 py-2'> <Link to='/our-story' className='whitespace-nowrap font-medium text-base'>Our Story</Link></li>
                  <li className='px-4 py-2'> <Link to='/my-sustainability-app' className='whitespace-nowrap font-medium text-base'>My Sustainability App</Link></li>
                  <li className='px-4 py-2'> <Link to='/careers' className='whitespace-nowrap font-medium text-base'>Careers</Link></li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className='hidden lg:block '>
          <Link to={'/pre-register'}>
            <button className='h-10 rounded-[30px] px-6 py-2 flex items-center justify-center text-white text-lg font-semibold bg-[#1E90FF] hover:bg-blue-700'>
              Pre-register
            </button>
          </Link>
        </div>

        <div className='block lg:hidden'>
          <div className='flex justify-center w-[35px] h-10' onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            <img src={menuButton} alt='menu logo'></img>
          </div>
          {isMobileMenuOpen && (
            <div className='absolute left-0 right-0 pt-8 z-20 mx-[60px]'>
              <ul className='w-full mr-[60px] text-start bg-white shadow-md rounded-[10px] gap-y-[14px] pl-[10px] pt-5 pr-10 pb-5'>
                <li className='px-4 py-2 font-medium text-base'>
                  <Link to={'/property-owners'}>Property Owners</Link>
                </li>
                <li className='px-4 py-2 font-medium text-base'>
                  <Link to={'/incentive-providers'}>Incentive Providers</Link>
                </li>
                <li className='px-4 py-2 font-medium text-base'>
                  <Link to={'/our-story'}>Our Story</Link>
                </li>
                <li className='px-4 py-2 font-medium text-base'>
                  <Link to={'/my-sustainability-app'}>My Sustainability App</Link>
                </li>
                <li className='px-4 py-2 font-medium text-base'>
                  <Link to={'/careers'}>Careers</Link>
                </li>
              </ul>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}
