import { useEffect, useState } from "react"





export const AssessmentAddress = (props) => {

    const [isEditing, setIsEditing] = useState(false)
    const [thisAddress, setThisAddress] = useState(props.address[props.addressIndex])

    const changeAddress = (form, e) => {

        if (form.checkValidity()) {
            props.address[props.addressIndex ].startEditing = null;
            e.preventDefault()
            let inputFields = form.querySelectorAll("input");

            setThisAddress({
                address_1: inputFields[0].value,
                address_2: inputFields[1].value,
                address_3: inputFields[2].value,
                city: inputFields[3].value,
                state: inputFields[4].value,
                country: inputFields[5].value,
                postal_code: inputFields[6].value
            })
            setIsEditing(false)
            props.selectedAddress.current = null
        }
        
    }

    useEffect(() => {
        if (props.address[props.addressIndex].startEditing) {
            setIsEditing(true)
        }
        let addressesList = props.address;
        addressesList[props.addressIndex] = thisAddress;
        props.changeAddress(addressesList)
    }, [thisAddress])

    useEffect(() => {
        props.setAddressBeingAdded(isEditing)
    }, [isEditing])

    if (!isEditing) {
        return <div className="Address flex items-center justify-between w-[100%]">
            <div className="flex items-center justify-center gap-[10px]">
                <input className="checkboxes-addresses-list transform scale:1.5" id={"checkbox-" + props.addressIndex} type="checkbox" onClick={(e) => {
                    let checkboxes = document.querySelectorAll(".checkboxes-addresses-list");
                    checkboxes.forEach((item) => {
                        item.checked = false
                    })
                    e.target.checked = true;
                    props.selectedAddress.current = props.addressIndex
                    
                }}/>
                <h1> {thisAddress.address_1}, {thisAddress.address_2 && thisAddress.address_2 + ", "} {thisAddress.address_3 && thisAddress.address_3 + ", "} {thisAddress.city}, {thisAddress.state}, {thisAddress.postal_code}</h1>
            </div>
            <button className="underline"onClick={() => {
                setIsEditing(true)
            }}> Edit Address </button>
        </div>
    }

    return <div className="address_is_editing w-[100%]">
        <form className={"w-[100%] flex flex-col gap-[15px] justify-center screen700:justify-start form-num-" + props.addressIndex}>
            <div className="address_lines flex flex-wrap items-center gap-[30px] justify-center screen700:justify-start">
                <div className="address_line flex flex-col items-baseline justify-center ">
                    <label htmlFor="address_line_1"> Address Line 1</label>
                    <input type="text" id="address_line_1" required defaultValue={thisAddress.address_1}/>
                </div>
                <div className="address_line flex flex-col items-baseline justify-center">
                    <label htmlFor="address_line_2"> Address Line 2</label>
                    <input type="text" id="address_line_2" defaultValue={thisAddress.address_2}/>
                </div>
                <div className="address_line flex flex-col items-baseline justify-center">
                    <label htmlFor="address_line_3"> Address Line 3</label>
                    <input type="text" id="address_line_3" defaultValue={thisAddress.address_3}/>
                </div>
            </div>

            <div className="city-and-state-input flex flex-wrap items-center gap-[30px] justify-center screen700:justify-start">
                <div className="city-and-state-input flex flex-col items-baseline justify-center">
                    <label htmlFor="city"> City</label>
                    <input type="text" id="city" required defaultValue={thisAddress.city}/>
                </div>
                <div className="city-and-state-input flex flex-col items-baseline justify-center">
                    <label htmlFor="state"> State </label>
                    <input type="text" id="state" required defaultValue={thisAddress.state}/>
                </div>
                <div className="city-and-state-input flex flex-col items-baseline justify-center">
                    <label htmlFor="country"> Country </label>
                    <input type="text" id="country" required defaultValue={thisAddress.country}/>
                </div>
            </div>

            <div className="postal_code_input flex flex-col items-baseline justify-center  self-center screen700:self-start">
                <label htmlFor="postal_code"> Postal code</label>
                <input type="text" id="postal_code" required defaultValue={thisAddress.postal_code}/>
            </div>

            <div className="buttons flex items-center justify-between w-[100%]">
                <button className="cancel-address-button" onClick={(e) => {
                    e.preventDefault()
                    props.setAddressBeingAdded(false)
                    if (props.address[props.addressIndex].startEditing) {
                        let addressList = [...props.address];
                        addressList.pop()
                        props.changeAddress(addressList)
                    }
                    setIsEditing(false)
                }}> Cancel </button>
                <button className="submit-address-button" onClick={(e) => {
                    changeAddress(document.querySelector(".form-num-" + props.addressIndex), e)
                }}> {props.address[props.addressIndex].startEditing? "Make address" : "Change address"} </button>
            </div>
        </form>
    </div>
}
