import { useNavigate } from "react-router-dom"
import "../../assets/style/Solutions.css"
import "./Home.css"
import MainHeader from "../../assets/components/js/MainHeader"
import MainFooter from "../../assets/components/js/MainFooter"

export const Solutions = () => {

    const navigate = useNavigate()

    return <div className="revised-home flex flex-col h-screen">
        <MainHeader />

        <div className="solutions-overview-container">
            <section className="solutions-introduction">
                <p>At Dreamline AI, our solutions are designed to address the critical needs of awareness, transparency, and accuracy in the deployment of capital for energy-efficient and clean energy projects. We understand that effective solutions require not only innovative technology but also a deep commitment to clear and precise impact measurement.</p>
                <p>Here's how we achieve this:</p>
            </section>
            <section className="solutions-section">
                <h2>Streamlined Capital Deployment</h2>
                <p>Our platform removes the complexities and delays traditionally associated with funding energy projects. By leveraging advanced AI algorithms, we ensure that capital is allocated efficiently and effectively, accelerating the pace of project initiation and completion. This rapid deployment helps overcome financial barriers and brings sustainable solutions to the forefront more quickly.</p>
            </section>
            <section className="solutions-section">
                <h2>Enhanced Project Management</h2>
                <p>We offer comprehensive project management tools that provide end-to-end oversight and control. From planning and implementation to monitoring and reporting, our platform integrates every aspect of project management into a seamless process. This holistic approach ensures that projects stay on track, on budget, and deliver the intended outcomes.</p>
            </section>
            <section className="solutions-section">
                <h2>Granular Impact Reporting</h2>
                <p>Transparency and accuracy are at the core of our reporting systems. Our platform provides detailed, real-time impact reports that offer insights into the performance and benefits of each project. Stakeholders can access precise data on energy savings, emissions reductions, and other key metrics, enabling them to make informed decisions and track progress with confidence.</p>
            </section>
            <section className="solutions-section">
                <h2>Data-Driven Decision Making</h2>
                <p>With our advanced analytics capabilities, stakeholders can harness the power of big data to drive smarter decisions. We compile and analyze vast amounts of data to identify trends, optimize resource allocation, and predict future outcomes. This data-driven approach enhances the accuracy and effectiveness of energy projects, ensuring that investments yield the highest possible returns.</p>
            </section>
            <section className="solutions-section">
                <h2>Increasing Awareness</h2>
                <p>We are committed to raising awareness about the importance and benefits of energy efficiency and clean energy. Through our educational resources, outreach programs, and strategic partnerships, we aim to inform and inspire individuals, communities, and organizations to embrace sustainable practices. By increasing awareness, we foster a culture of sustainability that extends beyond individual projects.</p>
            </section>
            <section className="solutions-section">
                <p>Dreamline AI is dedicated to providing solutions that not only meet but exceed the expectations of our clients and stakeholders. By focusing on awareness, transparency, and accuracy, we are transforming the landscape of energy project funding and management, paving the way for a more sustainable and efficient future.</p>
            </section>
        </div>

        <MainFooter />
    </div>
}
