import React, { useState } from 'react';
import info from '../../images/info.png';

const InfoPopup = () => {
    // State to manage popup visibility
    const [showPopup, setShowPopup] = useState(false);

    // Function to toggle popup visibility
    const togglePopup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log("Before toggle: ", showPopup);
        setShowPopup(!showPopup);
        console.log("After toggle: ", showPopup);
    };

    // In the render method
    console.log("Rendering, showPopup is: ", showPopup);
    return (
        <div className="flex items-center space-x-2">
            <div className="relative">
                <button onClick={togglePopup} className="flex items-center justify-center w-6 h-6 rounded-full" style={{ position: 'relative', top: '-4px' }}>
                    <img className="w-6 h-6" src={info} alt="info" />
                </button>
                {showPopup && (
                    <div className="absolute z-10 w-64 p-4 bg-white border border-gray-300 rounded-lg shadow-lg left-full top-0 ml-2">
                        <p>Here is some information...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InfoPopup;
