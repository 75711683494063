import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../../../images/dreamlineai-logo-simple.svg";

export const DreamLineLogo = () => {
  return (
    <Link to="/">
      <div className='flex w-[143px] h-10 items-end justify-left'>
        <img className='object-fit' src={logo} alt="dreamline ai logo" />
        <p className='text-2xl font-semibold w-[108px] h-[35px] text-[#1e90ff]'>reamline</p>
      </div>
    </Link>
  )
}