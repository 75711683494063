import React from 'react';
import { useNavigate } from 'react-router-dom';

export const LearnMoreButton = ({ link, text }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(link);
    };

    return (
      <button
        className='inline-block flex items-center h-10 rounded-[30px] px-6 py-2 text-white font-semibold text-lg bg-[#0070E0]'
        onClick={handleClick}
      >{text}
      </button>
    );
}