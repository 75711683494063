import { Link , useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import groupPic from '../../assets/images/group-pic.png';
import logo from '../../assets/images/logo.png';
import './signup.css';
import visible from '../../assets/images/visible.png';
import invisible from '../../assets/images/invisible.png';

const PASSWORD_HASH_FIELD_NAME = 'password_hash';
const FIRST_NAME_FIELD_NAME = 'first_name';
const LAST_NAME_FIELD_NAME = 'last_name';
const EMAIL_FIELD_NAME ='email';

export const SignUp = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState([]);
    const [firstNameError, setFirstNameError] = useState([]);
    const [lastNameError, setLastNameError] = useState([]);
    const [passwordError, setPasswordError] = useState([]);
    const [confirmPasswordError, setConfirmPasswordError] = useState([]);
    const navigate = useNavigate()

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Clear any error messages, if any
        setEmailError([]);
        setFirstNameError([]);
        setLastNameError([]);
        setPasswordError([]);
        setConfirmPasswordError([]);

        //backend URL can be found using that environmental variable, which equates to "http://127.0.0.1:6868/api"
        const backendRegisterURL = `${process.env.REACT_APP_SERVER_URL}/users/register`

        try {

            // Check if both of the provided passwords match. This is the only validation made
            // locally. Change this if needed in the future.
            if (password !== confirmPassword){
                setConfirmPasswordError(currErr => currErr.concat("*Passwords don't match."));
                throw new Error('Password mismatch.');
            }

            const bodyObj = {};
            bodyObj[EMAIL_FIELD_NAME] = email;
            bodyObj[FIRST_NAME_FIELD_NAME] = firstName;
            bodyObj[LAST_NAME_FIELD_NAME] = lastName;
            bodyObj[PASSWORD_HASH_FIELD_NAME] = password;
            const response = await fetch(backendRegisterURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyObj),
            });

            if (!response.ok) {
                // If server response is not ok, handle errors
                const errorsResponse = await response.json();

                // Add an error message for every invalid field
                errorsResponse.errors.forEach(error => {
                    if (error['type'] === 'field'){
                        // Update the DOM for every error respective to each field.
                        if (error['path'] === EMAIL_FIELD_NAME) {
                            setEmailError(currErr => currErr.concat('*' + error['msg']));
                        }
                        if (error['path'] === FIRST_NAME_FIELD_NAME) {
                            setFirstNameError(currErr => currErr.concat('*' + error['msg']));
                        }
                        if (error['path'] === LAST_NAME_FIELD_NAME) {
                            setLastNameError(currErr => currErr.concat('*' + error['msg']));
                        }
                        if (error['path'] === PASSWORD_HASH_FIELD_NAME) {
                            setPasswordError(currErr => currErr.concat('*' + error['msg']));
                        }
                    }
                })

                // Also show errors as an exception in the console.
                throw new Error(`Errors from server: ${JSON.stringify(errorsResponse)}`);
            }

            const data = await response.json(); // Parse JSON data from the response
            console.log('Success:', data);

            localStorage.setItem('token', data.token);

            navigate("/signup-profileselection")
            // Handle successful registration (e.g., redirect to login page, show success message)
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    return (
        <div
            className="flex min-h-screen bg-no-repeat"
            style={{
                backgroundImage: `url(${groupPic})`,
                minHeight: '100vh',
                width: '80%',
                margin: '20px auto',
            }}
        >

            <div className="w-5/6  p-10  my-14 mx-auto rounded-lg">
                <div className="w-full md:max-w-md md:min-w-0 bg-white bg-opacity-90 p-10 rounded-l-lg shadow-md mt-14 ml-auto">
                    <div>
                        <img className="mx-auto" src={logo} alt="" /> {/* Center the logo */}

                        <br />
                        <div>
                            <h1 className="text-left text-2xl font-bold mb-4">Sign Up</h1>
                        </div>
                        <div>
                            <p style={{ color: '#7B8499' }} className="text-left mb-4">Already have an account? <Link to="/signin" style={{ color: '#43CCC4', fontWeight: '700' }} className="text-blue-600 hover:underline cursor-pointer">Login!</Link></p>
                        </div>
                        <form onSubmit={(event) => handleSubmit(event)}>
                            <div >
                                <div>
                                    <input style={{ borderColor: emailError.length > 0 ? 'red' : '#43CCC4' }} className="px-4 w-full border py-4 rounded-md text-sm outline-none mt-1 mb-1" type="text" name="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {emailError.map((error, index) => <p className='error-info' key={index}>{error}</p>)}
                                </div>
                                <div>
                                    <input style={{ borderColor: firstNameError.length > 0 ? 'red' : '#43CCC4' }} className="px-4 w-full border py-4 rounded-md text-sm outline-none mt-4 mb-1" type="text" name="first_name" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    {firstNameError.map((error, index) => <p className='error-info' key={index}>{error}</p>)}
                                </div>

                                <input style={{ borderColor: lastNameError.length > 0 ? 'red' : '#43CCC4' }} className="px-4 w-full border py-4 rounded-md text-sm outline-none mt-4 mb-1" type="text" name="last_name" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {lastNameError.map((error, index) => <p className='error-info' key={index}>{error}</p>)}
                            <div className="relative">
                                <input style={{ borderColor: passwordError.length > 0 ? 'red' : '#43CCC4' }} className="px-4 w-full border py-4 rounded-md text-sm outline-none mt-4 mb-1" type={isPasswordVisible ? "text" : "password"} name="password_hash" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                {passwordError.map((error, index) => <p className='error-info' key={index}>{error}</p>)}
                                <button
                                    type="button"
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    className="absolute top-[22px] right-0 pr-4 flex items-center text-sm leading-5"                                >

                                    <img
                                        src={isPasswordVisible ? visible : invisible}
                                        alt={isPasswordVisible ? 'Hide password' : 'Show password'}
                                    />
                                </button>
                            </div>
                            <div className="relative mt-4 mb-1">
                                <input style={{ borderColor: confirmPasswordError.length > 0 ? 'red' : '#43CCC4' }} className="px-4 w-full border py-4 rounded-md text-sm outline-none mb-4" type={isPasswordVisible ? "text" : "password"} name="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <button
                                    type="button"
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    className="absolute top-[8px] right-0 pr-4 flex items-center text-sm leading-5"                                >
                                    <img
                                        src={isPasswordVisible ? visible : invisible}
                                        alt={isPasswordVisible ? 'Hide password' : 'Show password'}
                                    />
                                </button>
                            </div>
                            <div className="relative mb-4">
                                {confirmPasswordError.map((error, index) => <p className='error-info' key={index}>{error}</p>)}
                            </div>
                            </div>

                            <div>
                                <p className="font-info mb-4">By continuing you agree to the Community Dreams Foundation <Link to="/terms-of-service" className="text-blue-600 hover:underline cursor-pointer">terms of service</Link> and <Link to="/privacy-policy" className="text-blue-600 hover:underline cursor-pointer">privacy policy</Link>.</p>
                            </div>


                            <div>
                                <button className="mt-4 w-full bg-[#43CCC4] hover:bg-green-400 text-white py-3 rounded-2xl transition duration-100" type="submit">Register</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div >

        </div>
    );
};
