import React from 'react'
import { Link } from 'react-router-dom';

import careers1 from '../../assets/images/careers1.jpeg';
import workforceDevelopment from '../../assets/images/workforceDevelopment.jpeg';
import comprehensiveTraining from '../../assets/images/comprehensive_training.svg';
import mentorshipGuidance from '../../assets/images/mentorship_guidance.svg';
import realWorldExperience from '../../assets/images/real_world_experience.svg';
import networkingOpportunities from '../../assets/images/networking_opportunities.svg';
import careers2 from '../../assets/images/careers2.jpeg';
import joinUsCareers from '../../assets/images/joinUsCareers.jpeg';

import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';

export const Careers = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col item-center pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-8 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-4 xl:gap-y-6'>
          <div className='relative w-full h-90 flex justify-center'>
            <p className='absolute w-full top-[166px] h-18 xl:h-12 text-white font-semibold text-2xl xl:text-3.5xl z-10'>
              Careers
            </p>
            <img
              className='w-full h-full object-cover object-center rounded-[30px]'
              src={careers1}
              alt='Header'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-20 rounded-[30px]'></div>
          </div>
          <p className='max-w-[928px] mx-auto text-lg'>
            At Dreamline AI, we believe that building a sustainable future starts with empowering people. Our commitment to workforce development is at the heart of our mission, and we are dedicated to creating meaningful career opportunities that benefit both individuals and the communities we serve.
          </p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2'>
          <img
            className='w-full xl:w-136 h-90 rounded-[30px] object-cover object-top'
            src={workforceDevelopment}
            alt='working person'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='w-full font-semibold text-2xl'>Workforce Development</p>
            <p className='w-full text-base'>
              We prioritize recruiting talent directly from the communities where we operate. By doing so, we not only support local economies but also ensure that our workforce is diverse, inclusive, and deeply connected to the projects we undertake. This approach fosters a strong sense of ownership and pride among our team members, driving greater engagement and commitment to our mission.
            </p>
            {/* <LearnMoreButton link={'/property-management'} text={'Download'}/> */}
          </div>
        </div>

        <div className='flex flex-col gap-y-2 xl:gap-y-6 text-left'>
          <div className='flex flex-col gap-y-2'>
            <p className='w-full font-semibold text-3.5xl'>Internship Program</p>
            <p className='w-full text-base'>
              Our robust internship program is designed to nurture the next generation of IT professionals. We are building a force of 10,000 interns, providing unparalleled opportunities for skill development and hands-on work experience. Through this program, interns gain valuable insights into the latest technologies and methodologies in the energy sector, preparing them for successful careers in a rapidly evolving industry.
            </p>
          </div>

          <div className='flex flex-col gap-y-2'>
            <p className='font-semibold text-2xl'>
              Interns at Dreamline AI benefit from:
            </p>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div className='flex flex-col gap-y-2'>
                <img className='w-[29px] h-10' src={comprehensiveTraining} alt=''></img>
                <p className='max-w-136 h-6 font-semibold text-lg'>Comprehensive Training</p>
                <p className='max-w-136 text-base'>Our training modules cover a wide range of skills, from technical proficiency to project management, ensuring that interns develop a well-rounded skill set.</p>
              </div>
              <div className='flex flex-col gap-y-2'>
                <img className='w-[50px] h-10' src={mentorshipGuidance} alt=''></img>
                <p className='max-w-136 h-6 font-semibold text-lg'>Mentorship and Guidance</p>
                <p className='max-w-136 text-base'>Each intern is paired with a mentor who provides personalized support, helping them navigate their career path and achieve their professional goals.</p>
              </div>
              <div className='flex flex-col gap-y-2'>
                <img className='w-10 h-10' src={realWorldExperience} alt=''></img>
                <p className='max-w-136 h-6 font-semibold text-lg'>Real-World Experience</p>
                <p className='max-w-136 text-base'>Interns work on actual projects, contributing to meaningful initiatives that have a tangible impact on energy efficiency and sustainability.</p>
              </div>
              <div className='flex flex-col gap-y-2'>
                <img className='w-10 h-10' src={networkingOpportunities} alt=''></img>
                <p className='max-w-136 h-6 font-semibold text-lg'>Networking Opportunities</p>
                <p className='max-w-136 text-base'>Our program offers numerous opportunities to connect with industry professionals, fostering relationships that can lead to future career opportunities.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col items-center gap-y-2 xl:gap-y-8'>
          <div className='relative w-full h-90 rounded-[30px] overflow-hidden'>
            <img
              className='absolute top-0 xl:-top-44 left-0 w-full h-full xl:h-[746px] object-cover'
              src={careers2}
              alt='person doing work'
              loading='lazy'
            />
            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-20 rounded-[30px]'></div>
          </div>
          <div className='w-full xl:w-[929px]'>
            <p className='text-lg xl:text-base text-left lg:text-center'>
              At Dreamline AI, we are not just offering jobs; we are building careers and shaping future leaders in the energy sector.
              <br />
              By investing in our workforce and empowering individuals through education and hands-on experience, we are driving forward the innovation and change needed to create a more sustainable world.
            </p>
          </div>
          <div className='w-full grid grid-cols-1 lg:grid-cols-2 items-center gap-x-8 gap-y-2 xl:gap-y-4 pt-[21px] xl:pt-0'>
            <img
              className='w-full xl:w-136 h-90 xl:h-[280px] object-cover object-center rounded-[30px]'
              src={joinUsCareers}
              alt=''
              loading='lazy'
            />
            <div className='w-full xl:w-136 flex flex-col gap-y-2 text-left'>
              <div className='w-full flex flex-col items-start gap-y-4 xl:gap-y-6'>
                <div className='flex flex-col max-w-[543px] gap-y-2'>
                  <p className='font-semibold text-3.5xl'>Join Us</p>
                  <p className='text-base'>
                    Explore career opportunities at Dreamline AI and be part of a team that is committed to transforming the future of energy.
                  </p>
                </div>
                <Link to='/careers'>
                  <button
                    className='inline-block h-10 flex items-center rounded-[30px] px-6 xl:px-14 py-2 text-white font-semibold text-lg bg-[#0070E0]'
                  >Get in touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};