import React from 'react';
import InfoPopUp from '../../../assets/components/js/InfoPopUp';

const ProfileOption = ({ icon, label, value, selectedValue, onChange }) => {
    return (
        <label className="flex flex-col items-center m-3">
            <input type="radio" name="option" value={value} onChange={onChange} checked={selectedValue === value} />
            <img src={icon} alt={label} className="icon mb-2"/>
            <div className="flex">
                <span className="text-sm font-semibold mr-2">{label}</span>
                <InfoPopUp />
            </div>
        </label>
    );
};

export default ProfileOption;