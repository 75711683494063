import React from 'react';
import { useNavigate } from 'react-router-dom';
import groupPic from '../../assets/images/group-pic.png';
import logo from '../../assets/images/logo.png';
import { useState } from 'react';


export const HomeownerP = () => {
    const [selectedGender, setSelectedGender] = useState('');
    const navigate = useNavigate();
    const handleChange = (event) => {
        setSelectedGender(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Perform  API call here
            alert("Backend API call needs to be added here!");
            navigate('/energy-assessment-home');
        } catch (error) {
            console.error("Error occurred during form submission");
        }
    }
    return (


        <>
            <div
                className="flex min-h-screen bg-no-repeat"
                style={{
                    backgroundImage: `url(${groupPic})`,
                    minHeight: '100vh',
                    width: '80%',
                    margin: '20px auto',
                }}
            >
                <div className="w-full  p-8  my-14 mx-auto rounded-lg">
                    <div className="w-full md:max-w-lg md:min-w-0  bg-white bg-opacity-90 p-10 rounded-l-lg mt-14 ml-auto">
                        <div>
                            <img className="mr-4" src={logo} alt="" />

                            <br />
                            <div>
                                <h1 className="text-left text-2xl font-bold mb-4">Tell us a bit about abour yourself</h1>
                            </div>
                            <p style={{ color: '#2B74F1' }} className="text-left mb-4 text-base font-bold">Why are we asking this? </p>
                            <div className="mb-4">
                                <label htmlFor="dateOfBirth" className="mb-1 block text-left text-sm font-medium text-gray-700">
                                    Date of Birth
                                </label>

                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="text"
                                    name="dateOfBirth"
                                    id="dateOfBirth"
                                    placeholder="MM/DD/YYYY"
                                    className="h-12 w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />

                            </div>

                            <div className="mb-4">
                                <label htmlFor="phone" className="mb-1 text-left block text-sm font-medium text-gray-700">
                                    Phone Number
                                </label>
                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    placeholder="(888) - 888 - 8888"
                                    className="h-12 w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />
                            </div>
                            <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="address" className=" block text-left text-sm font-medium text-gray-700">
                                    Gender
                                </label>
                                <div className="flex space-x-4 p-2">

                                    {['male', 'female', 'other'].map((gender) => (
                                        <label key={gender} className="inline-flex items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                className="sr-only peer"
                                                name="gender"
                                                value={gender}
                                                checked={selectedGender === gender}
                                                onChange={handleChange}
                                            />
                                            <span
                                                className={`px-6 py-2 rounded-full border-2 ${selectedGender === gender
                                                    ? 'bg-[#43CCC4] text-white border-[#43CCC4]'
                                                    : 'bg-white text-[#43CCC4] border-[#43CCC4]'
                                                    }`}
                                            >
                                                {gender.charAt(0).toUpperCase() + gender.slice(1)}
                                            </span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="address" className="mb-1 block text-left text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="text"
                                    name="address"
                                    id="address"
                                    placeholder="Street Address"
                                    className="mb-6 h-12 w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />
                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    placeholder="Apt, Suite # (Optional)"
                                    className="mb-6 h-12 w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />
                            </div>

                            <div className="flex gap-4 mb-4 w-full h-12">
                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder="State"
                                    className="w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />
                                <input
                                    style={{ borderColor: '#43CCC4' }}
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    placeholder="Zip Code"
                                    className="w-1/2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                                />
                            </div>
                            <div>
                                <button className="mt-4 w-5/6 bg-[#43CCC4] hover:bg-green-400 text-white py-3 rounded-2xl transition duration-100" type="submit">Next</button>
                            </div>


                        </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};
