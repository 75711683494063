import { useEffect, useRef, useState } from "react";
import "../css/Assessment.css"//"./Assessment.css"


export const TimeZone = (props) => {

    const [displayedTimes, setDisplayedTimes] = useState([])
    const [currentTimeZone, setCurrentTimeZone] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [fetchedDates, setFetchedDates] = useState(null)
    const [note, setNote] = useState()
    const [takenUpTimes, setTakenUpTimes] = useState()
    const selectedTimeZoneReference = useRef()

    const ianaTimeZones = [
        "Pacific/Honolulu",
        "America/Anchorage",
        "America/Los_Angeles",
        "America/Denver",
        "America/Chicago",
        "America/New_York",
        "America/Caracas",
        "America/Halifax",
        "America/Sao_Paulo",
        "America/Buenos_Aires",
        "Atlantic/Azores",
        "Europe/London",
        "Europe/Paris",
        "Europe/Berlin",
        "Europe/Moscow",
        "Africa/Cairo",
        "Africa/Johannesburg",
        "Asia/Jerusalem",
        "Asia/Dubai",
        "Asia/Kolkata",
        "Asia/Shanghai",
        "Asia/Tokyo",
        "Australia/Sydney",
        "Pacific/Auckland"
    ];

    const timeZoneAbbreviations = [
        { abbreviation: "HST", fullName: "Hawaii Standard Time" },
        { abbreviation: "AKST", fullName: "Alaska Standard Time" },
        { abbreviation: "PST", fullName: "Pacific Standard Time" },
        { abbreviation: "MST", fullName: "Mountain Standard Time" },
        { abbreviation: "CST", fullName: "Central Standard Time" },
        { abbreviation: "EST", fullName: "Eastern Standard Time" },
        { abbreviation: "VET", fullName: "Venezuelan Standard Time" },
        { abbreviation: "AST", fullName: "Atlantic Standard Time" },
        { abbreviation: "BRT", fullName: "Brasília Time" },
        { abbreviation: "ART", fullName: "Argentina Time" },
        { abbreviation: "AZOT", fullName: "Azores Time" },
        { abbreviation: "GMT", fullName: "Greenwich Mean Time" },
        { abbreviation: "CET", fullName: "Central European Time" },
        { abbreviation: "CET", fullName: "Central European Time" },
        { abbreviation: "MSK", fullName: "Moscow Standard Time" },
        { abbreviation: "EET", fullName: "Eastern European Time" },
        { abbreviation: "SAST", fullName: "South Africa Standard Time" },
        { abbreviation: "IST", fullName: "Israel Standard Time" },
        { abbreviation: "GST", fullName: "Gulf Standard Time" },
        { abbreviation: "IST", fullName: "Indian Standard Time" },
        { abbreviation: "CST", fullName: "China Standard Time" },
        { abbreviation: "JST", fullName: "Japan Standard Time" },
        { abbreviation: "AEDT", fullName: "Australian Eastern Daylight Time" },
        { abbreviation: "NZDT", fullName: "New Zealand Daylight Time" }
    ];

    const changeTimeZone = (timeZone) => {
        setDisplayedTimes(extractAndConvertTime(fetchedDates, timeZone))
        props.setSelectedTimeZone({
            timeZone: timeZone,
            timeZoneName: timeZoneAbbreviations[ianaTimeZones.findIndex(element => element == timeZone)]
        })
        fetchTimeSlots()
    }

    const ensureTwoDigits = (num) => {
        return num < 10? '0' + num : num; 
    }

    const translateISOSFormat = (isosString) => {
        let [date, time] = isosString.split("T")
        let month = date.split("-")[1]
        let monthNum = parseInt(month);
        date = date.split("-")[0] + "-" + ensureTwoDigits(monthNum) + "-" + date.split("-")[2]
        time = time.split(".")[0]
        return date + " " + time
    }

    useEffect(() => {
        if (!props.selectedDay) return;
        //Fetch request to backend for the specific times for the selected day goes here. Gets updated whenever, the day changes (props has useState of day in it)
        if (selectedTimeZoneReference.current) {
            selectedTimeZoneReference.current.classList.remove("time-zone-selected")
            selectedTimeZoneReference.current = null;
        }

        setSelectedTime()

        let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCurrentTimeZone(currentTimeZone)
        props.setSelectedTimeZone({
            timeZone: currentTimeZone,
            timeZoneName: timeZoneAbbreviations[ianaTimeZones.findIndex(element => element == currentTimeZone)]
        })   

        const selectedYear = props.selectedDay.getFullYear()
        const selectedMonth = props.selectedDay.getMonth()
        const selectDate = props.selectedDay.getDate()
        let fetchedDays = ["" + selectedYear + "-" + ensureTwoDigits(selectedMonth) + "-" + ensureTwoDigits(selectDate)+" 15:00:00",
                           "" + selectedYear + "-" + ensureTwoDigits(selectedMonth) + "-" + ensureTwoDigits(selectDate)+" 18:00:00",
                           "" + selectedYear + "-" + ensureTwoDigits(selectedMonth) + "-" + ensureTwoDigits(selectDate)+" 20:00:00"];
        
        setFetchedDates(fetchedDays)

        //fetch time slots to acquire them when new date is selected
        // fetchTimeSlots()
    
    }, [props.selectedDay])

    const fetchTimeSlots = async () => {

        if (!props.selectedDay) return;
        //for getting the current date's time information
        const selectedYear = props.selectedDay.getFullYear()
        const selectedMonth = props.selectedDay.getMonth() //add one due to difference in how SQL and Date() class count months
        const selectDate = props.selectedDay.getDate()

        //these are for checking whether the timeslot fall within the user's current timezone
        const dateTimeStart = new Date(selectedYear, selectedMonth, selectDate - 1)
        const dateTimeEnd = new Date(selectedYear, selectedMonth, selectDate + 1)
        
        
        let urlStart = translateISOSFormat(dateTimeStart.toISOString()).split(" ")[0]
        let urlEnd = translateISOSFormat(dateTimeEnd.toISOString()).split(" ")[0]
        
        //URL and token for user's authentication information. See backend for how URL works
        const fetchURL = `${process.env.REACT_APP_SERVER_URL}/timeslot/getTimeslotsDuring/${urlStart}/${urlEnd}`
        const token = localStorage.getItem('token');


        // fetch request
        try {
            await fetch(fetchURL, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
            }).then(async (response) => {
                let responseData = await response.json(); // acquire response data

                let returnTimes = [] // for when data is parsed into Date() readable info
                responseData.forEach((item) => { // get date_time for each, since that is what is needed
                    returnTimes.push(item.date_time)
                })

                //this function is what parses it into a Date() readable constructor
                returnTimes.forEach((item, index) => {
                    let [date, time] = item.split("T")
                    let month = date.split("-")[1]
                    let monthNum = parseInt(month) - 1;
                    date = date.split("-")[0] + "-" + ensureTwoDigits(monthNum) + "-" + date.split("-")[2]
                    time = time.split(".")[0]
                    returnTimes[index] = date + " " + time
                })
                
                
                setTakenUpTimes(returnTimes) //set state variable to it
            }).catch((error) => {
                console.log(error) //console.log any errors
            });

        }
        catch (e) {
            console.log(e)
        }
    }

    let extractAndConvertTime = (timeSlots, timezone) => {

        if (!props.selectedDay) return; // for avoiding errors that appear as soon as component is rendered

        //get the current year. For comparing if the timeslot falls within the current day + timezone
        let [cYear, cMonth, cDay] = [props.selectedDay.getFullYear(), props.selectedDay.getMonth(), props.selectedDay.getDate()]
        const startCurrentDate = new Date(cYear, cMonth, cDay) //start of selected day
        const endCurrentDate = new Date(cYear, cMonth, cDay, 24) //end of selected day

        if (!timeSlots) {
            return;
        }

        let currentDate = new Date() // for later to check if timeslot passed for today

        //will store all the info needed to render timeslot button and value
        let dateObjects = []

        //intl is used to fromat timeslot into desired timezone and time format
        let intl = new Intl.DateTimeFormat('en-US', {
            hour: "numeric",
            minute: "numeric",
            timeZone: timezone
        })


        //iterate through every fetched time slots
        for(let i = 0; i < timeSlots.length; i++) {

            //get all parts of the timeslot into a Date.UTC() readable constructor
            const [datePart, timePart] = timeSlots[i].split(" ")
            const [hours, minutes, seconds] = timePart.split(":");
            const [year, month, day] = datePart.split("-")

            //initiate Date.UTC() constructor
            let result = new Date(Date.UTC(year, month, day, hours, minutes, seconds))
            
            //It is checked whether the timeslot has already passed, such as when it is scheduled for 3, but it is already 6
            //Then, it is checked as to whether the time slot actually falls within the selected timezone's day range
            //That is what start, end, and current dates are used for
            if (currentDate < result && result >= startCurrentDate && result < endCurrentDate) {
                dateObjects.push({
                    numericTime: intl.format(result),
                    fetchedDate: timeSlots[i]
                })  
            }
        }

        //sort them, since SQL DB allows them to be out of order
        dateObjects.sort((a, b) => new Date(a.fetchedDate) - new Date(b.fetchedDate))

        return dateObjects
    }

    useEffect(() => {
        
        fetchTimeSlots()

        //setDisplayedTimes(returnVal)

    }, [fetchedDates])

    useEffect(() => {
        
        if (!fetchedDates) return;

        let uniqueTimes = []
        fetchedDates.forEach((item) => {
            if (!takenUpTimes.includes(item)) {
                uniqueTimes.push(item)
            }
        })

        let returnVal = extractAndConvertTime(uniqueTimes, currentTimeZone)
        setDisplayedTimes(returnVal)
        
    }, [takenUpTimes])

    useEffect(() => {
        props.changeTimeSlot(selectedTime)
    }, [selectedTime])

    useEffect(() => {
        props.setNote(note)
    }, [note])


    if (!props.selectedDay) {
        return <div className="time-zone flex-1 h-[100%]">
        <div className="time-zone-select-section">
            <h1 className="text-left"> Timezone</h1>
            <p className="text-left"> Please select a date.</p>
        </div>
    </div>
    }

    return <div className="time-zone flex-1 h-[100%]">
        <div className="time-zone-select-section flex flex-col gap-[15px] items-baseline justify-center">
            <h1 className="text-left"> Timezone</h1>
            <select className="rounded-[30px] p-[13px] w-[80%] min-w-[300px]" style={{
                border: "solid 2px gray"
            }} value={currentTimeZone}onChange={(e) => {
                changeTimeZone(e.target.value)
                setCurrentTimeZone(e.target.value)
            }}>
                {timeZoneAbbreviations.map((item, index) => {
                    return <option className="w-[20px]" key={index}value={ianaTimeZones[index]}>{item.abbreviation}, {item.fullName}</option>
                })}
            </select>
            <h1> Select a Time Slot</h1>
            <div className="time-slots flex-wrap flex items-baseline">
                {displayedTimes && displayedTimes.length > 0 ? displayedTimes.map((item, index) => {
                    
                    return <button key={index} onClick={(e) => {
                        if (selectedTimeZoneReference.current) {
                            selectedTimeZoneReference.current.classList.remove("time-zone-selected")
                        }
                        e.target.classList.add("time-zone-selected")
                        selectedTimeZoneReference.current = e.target;
                        setSelectedTime(e.target.value)
                    }} value={item.fetchedDate}className={ "time-zone-select-" + index + " mb-[10px] transition-all duration-100 time-zone-button p-[10px] rounded-[20px] mr-[20px]"} style={{
                    
                    }}> {item.numericTime}</button>
                }) : <h1 className="font-bold text-[red]"> No time slots available for this day.</h1>}
            </div>
            <label htmlFor="note-input"> Note <span className="text-gray-300"> (optional)</span></label>

            <textarea id="note-input" className="p-[10px] w-[80%] min-w-[300px] h-[120px] rounded-[30px]" style={{
                border: "2px solid rgb(169, 169, 169)",
            }} defaultValue={""} onChange={(e) => {
                setNote(e.target.value)
            }} />
        </div>
    </div>
}
