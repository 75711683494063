import React from 'react'
import { Link } from 'react-router-dom';

import ourStoryMain from '../../assets/images/ourStory.jpeg';
import ourStory1 from '../../assets/images/ourStory1.jpeg';
import ourStory2 from '../../assets/images/ourStory2.jpeg';
import ourStory3 from '../../assets/images/ourStory3.jpeg';

import { Header } from '../../assets/components/js/navigation/Header';
import { Footer } from '../../assets/components/js/navigation/Footer';

export const OurStory = () => {
  return (
    <div className='flex flex-col w-full items-center bg-[#F5F5F5]'>

      <div className='w-full flex justify-center items-center bg-white'>
        <Header />
      </div>

      <div className='flex flex-col item-center pt-8 pb-10 xl:pb-21 w-full xl:w-[1120px] px-8 xs:px-15 xl:px-0 gap-y-6 lg:gap-y-10 xl:gap-y-16'>

        <div className='flex flex-col items-center gap-y-2 xl:gap-y-6'>
          <div className='relative w-full h-90 overflow-hidden rounded-[30px]'>
            <p className='absolute w-full top-[220px] h-18 lg:top-[166px] lg:h-12 text-[#F5F5F5] font-semibold text-2xl lg:text-3.5xl z-10'>
              OurStory
            </p>
            <img
              className='absolute top-0 lg:top-[-274px] w-full h-full lg:h-[746px] object-cover lg:object-fill'
              src={ourStoryMain}
              alt='Our Story Seedling'
              loading='lazy'
            />
          </div>
          <p className='w-full text-base lg:text-xl xl:hidden'>
            It shouldn't be hard to access supports to fight climate change, but every year green energy funding goes unused.
          </p>
          <p className='hidden xl:block max-w-[928px] h-40 mx-auto text-lg'>
            Dreamline AI was born from a vision to revolutionize the way capital is deployed for energy-efficient and clean energy projects. In a world increasingly aware of the urgent need for sustainable solutions, our founders recognized the barriers that often hinder the swift deployment of resources where they are most needed. Thus, Dreamline AI was established with a mission to streamline these processes, making it easier and faster to fund and manage impactful energy initiatives.
          </p>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-2 xl:gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-right xl:w-136 xl:h-120'
            src={ourStory1}
            alt='laptops and gadgets'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='w-full xl:w-[543px] text-lg'>
              Our journey began with a dedicated team of experts in energy, finance, and technology who shared a common goal: to reduce the complexities and delays associated with traditional funding and project management. By leveraging advanced artificial intelligence and innovative technologies, we developed a platform that accelerates incentive deployment, optimizes project management, and provides detailed, real-time impact reporting.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-2 xl:gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-left xl:w-136 xl:h-120 lg:order-last'
            src={ourStory2}
            alt='vertical garden'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='w-full xl:w-[543px] text-lg'>
              Dreamline AI stands out by offering solutions that break down the traditional barriers to investment in energy efficiency and renewable projects. Our platform simplifies the application and approval processes, ensuring rapid deployment of incentives and resources. This not only speeds up project timelines but also maximizes the positive impact on communities and the environment.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-x-8 gap-y-2 xl:gap-y-6 xl:gap-y-0'>
          <img
            className='w-full h-90 rounded-[30px] object-cover object-center xl:w-136 xl:h-120'
            src={ourStory3}
            alt='people meeting'
            loading='lazy'
          />
          <div className='flex flex-col w-full xl:w-[543px] gap-y-2 text-left'>
            <p className='w-full xl:w-[543px] text-lg'>
              We are committed to transparency and accountability, providing granular reporting on the outcomes and benefits of each project. This allows stakeholders to track progress and measure the real-world impact of their investments.
            </p>
          </div>
        </div>

        <div className='w-full flex flex-col justify-center gap-y-6 xl:gap-y-7'>
          <p className='max-w-[1117px] text-base'>
            At Dreamline AI, our story is one of innovation, commitment, and a relentless pursuit of a sustainable future. We are proud to play a pivotal role in driving the transition to a cleaner, more efficient energy landscape. Join us as we continue to break down barriers, accelerate progress, and create lasting positive change for our planet and future generations.
          </p>
          <Link to='/pre-register'>
            <button
              className='inline-block h-10 rounded-[30px] px-14 py-2 text-white font-semibold text-lg bg-[#0070E0]'
            >Get started
            </button>
          </Link>
        </div>
      </div>

      <div className='w-full flex justify-center bg-white'>
        <Footer />
      </div>

    </div>
  )
};